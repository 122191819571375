<template>
  <lila-default-template>
    <template #mainContent>
      <div :class="loadingContent ? 'loading large' : ''">
        <form
          v-show="!loadingContent"
          class="container basic-info-form"
          @submit.prevent="submitForm()"
        >
          <h1 class="title">
            Novo Paciente
          </h1>

          <lila-message
            v-if="alert.location == 'page'"
            :alert-data="alert"
          />

          <div class="columns">
            <div class="column is-5">
              <div class="field-group">
                <div class="field-group-header align-title">
                  <p>Paciente</p>
                </div>

                <div class="field-group-body">
                  <lila-input
                    v-model="v.data.name.$model"
                    placeholder="Nome*"
                    :validation="v.data.name"
                  />

                  <lila-input
                    v-model="v.data.registerNumber.$model"
                    placeholder="Número de Matrícula"
                    :validation="v.data.registerNumber"
                  />
                  
                  <lila-select
                    v-model="v.data.gender.$model"
                    placeholder="Sexo*"
                    :options="genderList"
                    :validation="v.data.gender"
                  />

                  <lila-mask
                    v-model="v.data.cpf.$model"
                    placeholder="CPF*"
                    mask="###.###.###-##"
                    :validation="v.data.cpf"
                  />

                  <lila-mask
                    v-model="v.data.phone.$model"
                    placeholder="Telefone*"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    :validation="v.data.phone"
                  />

                  <lila-input
                    v-model="v.data.email.$model"
                    placeholder="E-mail"
                    :validation="v.data.email"
                  />

                  <lila-select
                    v-model="v.data.attendancePlaceId.$model"
                    :placeholder="$Session.get($userSessionName).profile == 'professional_leader' ?
                      'Equipes*' :
                      'Local de Atendimento*'"
                    :options="medicalCareLocationList"
                    :validation="v.data.attendancePlaceId"
                  />
                </div>
              </div>
            </div>

            <div class="column is-2 middle-column">
              <div class="line" />
            </div>
          </div>

          <div class="registration-steps has-text-centered">
            <ul class="steps">
              <li class="active" />
              <li />
              <li />
              <li />
            </ul>
          </div>

          <lila-button
            type="submit"
            title="Salvar"
            class="submit-buttons"
            :loading="loading"
          />
        </form>

        <div
          v-show="!loadingContent"
          class="default-modal app-code-modal"
        >
          <div
            class="modal"
            :class="appCodeModal.active ? 'is-active' : ''"
          >
            <div class="modal-background" />

            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">
                  O paciente foi incluido e as instruções para o uso do aplicativo será encaminhada por sms e e-mail.
                </p>
              </header>

              <section
                v-show="alert.location == 'modal' && alert.show"
                class="modal-card-body"
              >
                <lila-message
                  v-if="alert.location == 'modal'"
                  :alert-data="alert"
                />
              </section>

              <footer class="modal-card-foot">
                <lila-button
                  v-if="!appCodeModal.invalidPhone"
                  type="button"
                  title="OK"
                  class="button"
                  :is-centralized="true"
                  :loading="sendingCode"
                  @customClickEvent="sendCode()"
                />

                <lila-button
                  v-else
                  type="button"
                  title="Continuar cadastro"
                  class="button"
                  :is-centralized="true"
                  @customClickEvent="goToStep2()"
                />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";

import { required, email } from "@vuelidate/validators";

import GenderOptions from "@assets/options/gender.json";

const validCpf = function(value){
  if(value) return this.$Utilities.validateCPF(value);

  return true;
};

const verifyLastName = function(value){
  let name = value.trim().replace(/\s\s+/g, " ").split(" ");

  if(typeof name[1] == "undefined" || (typeof name[1] != "undefined" && name[1].length < 1)) return false;

  return true;
};

export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate,
    LilaMask
  },
  data() {
    return {
      data: {
        id: "",
        name: "",
        gender: "",
        cpf: "",
        phone: "",
        email: "",
        registerNumber: "",
        attendancePlaceId: null
      },
      genderList: GenderOptions.options,
      medicalCareLocationList: this.$Session.get(this.$userSessionName).doctor.Addresses[0] ? this.$Session.get(this.$userSessionName).doctor.Addresses.map(item => {
        return {
          _id: item.Id,
          name: item.Street
        };
      }) : [],
      appCodeModal: {
        active: false,
        invalidPhone: false
      },
      loading: false,
      loadingContent: false,
      sendingCode: false,
      alert: {
        message: "",
        type: "",
        show: false,
        location: ""
      }
    };
  },
  validations() {
    return {
      data: {
        name: {
          required,
          verifyLastName
        },
        gender: {required},
        cpf: {
          required,
          validCpf
        },
        phone: {
          required
        },
        email: {email},
        registerNumber: {},
        attendancePlaceId: {required}
      }
    };
  },
  created() {
    if(this.$Session.get(this.$userSessionName).profile == "professional_leader"){
      this.getTeams();
    }
  },
  methods: {
    submitForm(){
      this.alert.show = false;
      this.alert.location = "page";

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      let patient = Object.assign({}, this.data);

      if(patient.email == "") delete patient.email;
      if(this.$Session.get(this.$userSessionName).profile == "professional_leader"){
        patient.TeamId = patient.attendancePlaceId;

        delete patient.attendancePlaceId;
      }

      this.$axios({
        method: "POST",
        url: "api/patient",
        data: patient,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(res => {
          this.data.id = res.data.Id;

          //this.openModal();
          this.sendCode();
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    getTeams(){
      this.loadingContent = true;

      this.$axios({
        method: "GET",
        url: "api/team",
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then((res) => {
          this.medicalCareLocationList = res.data.map(item => {
            return {
              _id: item.Id,
              name: item.Name
            };
          });
        })
        .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, "danger"))
        .finally(() => this.loadingContent = false);
    },
    openModal(){
      this.appCodeModal.active = true;
    },
    sendCode(){
      this.alert.show = false;
      this.alert.location = "modal";
      this.sendingCode = true;
      this.loading = true;

      this.$axios({
        method: "POST",
        url: `api/patient/token/${this.data.id}`,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => this.$router.push({name: "step2PatientInformation",
          query: {
            customMessageType: "success",
            customMessage: "O paciente foi inserido com sucesso e as instruções para o uso do aplicativo foram encaminhadas por SMS e e-mail. Se desejar, você pode cadastrar informações complementares."
          },
          params: {
            id: this.data.id
          }}))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          if(error.response.status == 400){
            this.appCodeModal.invalidPhone = true;
            //this.$Utilities.controlAlert(null, this.alert, 'warning', 'O e-mail foi enviado, porem não foi possivel enviar o SMS. Verifique se o telefone do paciente esta correto.');

            this.$router.push({name: "step2PatientInformation",
            query: {

                  customMessageType: "warning",
                  customMessage: `O paciente foi inserido com sucesso e as instruções para o uso do aplicativo foram encaminhadas por e-mail. Se desejar, você pode cadastrar informações complementares.
                                                                      <br><br>
                                                                      Porem não foi possivel enviar o SMS. Verifique se o telefone do paciente esta correto.`

            },
              params: {
                id: this.data.id

              }});
          }else{
            this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
          }
        })
        .finally(() => {
          this.sendingCode = false;
          this.loading = false;
        });
    },
    closeModal(){
      this.$router.push({name: "step2PatientInformation",
      query: {
        customMessageType: "success",
        customMessage: "O Paciente foi inserido com sucesso. Se desejar você pode cadastrar as informações complementares."
      },
        params: {
          id: this.data.id
        }});
      this.appCodeModal.active = false;
    },
    goToStep2(){
      this.$router.push({name: "step2PatientInformation",
      query: {
        customMessageType: "success",
        customMessage: "O Paciente foi inserido com sucesso e as instruções para o uso do aplicativo foi encaminhado por sms e e-mail. Se desejar você pode cadastrar as informações complementares."
      },
        params: {
          id: this.data.id
        }});
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';
</style>
