<template>
  <div class="conversation-card conversation-card-component">
    <div
      v-for="patient in dataList"
      :key="$Utilities.getValueIgnoreCase(patient, 'id')"
      class="card"
      :class="[
        cardClass($Utilities.getValueIgnoreCase(patient, 'doctorPriority')),
        selectedPatient && ($Utilities.getValueIgnoreCase(selectedPatient, 'id') == $Utilities.getValueIgnoreCase(patient, 'id')) ? 'active' : '']"
      @click="clickEvent(patient)"
    >
      <router-link :to="{name: routerPath, params: {id: $Utilities.getValueIgnoreCase(patient, 'id')}}">
        <div class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <div class="is-profile-picture-64x64">
                  <img
                    :src="getProfilePicture(patient.profilePicture)"
                    alt="Foto do paciente"
                  >
                </div>

                <div class="amount-message">
                  {{ amountMessage(patient.amountMessage) }}
                </div>
              </figure>
            </div>

            <div class="media-content">
              <div class="content">
                <div class="content-line">
                  <div class="patient">
                    {{ patient.name }}
                  </div>

                  <div class="cid">
                    {{ patient.cid }}
                  </div>
                </div>

                <div class="message">
                  {{ messageSlice(patient.message) }}
                </div>

                <div class="date">
                  <span>{{ patient.date }}</span>

                  <div
                    v-show="patient.deletingTicket"
                    class="loading very-small"
                  />

                  <lila-svg-icon
                    v-show="!patient.deletingTicket"
                    :src="require(`@assets/icons/delete.svg`)"
                    class="is-cursor-pointer"
                    @customClickEvent="deleteTicketCard(patient)"
                  />
                </div>
              </div>
            </div>
          </article>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@components/shared/images/SvgIcon.vue";

export default {
  components: {
    "lila-svg-icon": SvgIcon
  },
  props: {
    dataList: {
      required: true,
      type: Array,
      default: () => {
        return [{
          id: "",
          name: "",
          profilePicture: "",
          cid: "",
          message: "",
          amountMessage: 0,
          doctorPriority: "",
          date: ""
        }];
      }
    },
    selectedPatient: {
      required: false,
      type: Object,
      default: null
    },
    routerPath: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    clickEvent(patient){
      this.$emit("customClickEvent", patient);
    },
    deleteTicketCard(patient){
      this.$emit("customDeleteTicketCard", patient);
    },
    cardClass(doctorPriority) {

      switch (doctorPriority) {
      case "urgent":
        return "conversation-urgent";

      case "moderate":
        return "conversation-moderate";

      case "low":
        return "conversation-low";
      }
    },
    getProfilePicture(profilePicture) {
      return profilePicture != "" ? profilePicture : require(`@components/shared/lila/profilePicturePlaceholder/${(Math.floor(Math.random() * 5) + 1)}.png`);
    },
    messageSlice(message){
      if(message?.length > 75) return `${message.substring(0, 75)}...`;

      return message;
    },
    amountMessage(amount){
      if(amount > 99) return "99+";

      return amount;
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/ticketCard.css';
</style>
