<template>
  <div class="main">
    <PopupAlert 
      v-show="$Session.get($userSessionName).isTrial"
    />
    
    <lila-terms
      :show-accept-terms="false"
      :modal-active="activeModal"
    />
        
    <lila-tutorial ref="tutorial" />
    
    <section class="section topo">
      <nav
        class="navbar main-menu"
        role="navigation"
        aria-label="main navigation"
      >      
        <div class="container">
          <div class="navbar-brand">
            <div class="navbar-item">
              <img
                :src="getImagePath(selectLogo())"
                class="logo-lila"
              >
            </div>
            
            <div
              class="navbar-burger"
              aria-expanded="burgerActive"
              @click="toggleBurger"
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div class="navbar-item">
            <label> Lila Profissional </label>
          </div>
          <div
            id="navbarBasicExample"
            class="navbar-menu"
            :class="{ 'is-active': burgerActive }"
          >
            <div class="navbar-end">
              <div
                v-for="route in itensMenu"
                :key="route.name"
                class="box-navbar-item"
              >
                <div
                  v-if="verifyItemAction(route) == 'userName' && userName != ''"
                  class="navbar-item professional-menu"
                >
                  {{ institutionName }}
                </div>
                
                <div
                  v-if="verifyItemAction(route) == 'submenu'"
                  class="navbar-item has-dropdown is-hoverable"
                >
                  <a
                    class="navbar-link"
                  >
                    <figure
                      v-if="$Session.get($userSessionName).professional.Photo"
                      class="image doctor-avatar"
                    >
                      <div class="is-profile-picture-60x60">
                        <img
                          :src="$Session.get($userSessionName).professional.Photo"
                          class="circular-mask"
                        >
                      </div>
                    </figure>

                    <img
                      v-else
                      :src="userGender == null ? require('@/assets/images/default_avatar_neutral.png') : (userGender.toLowerCase() === 'masculino' ? require('@/assets/images/default_avatar_male.png') : require('@/assets/images/default_avatar_female.png'))"
                      class="default-avatar"
                    >
                  </a>



                  <div class="navbar-dropdown is-right">
                    <strong class="navbar-items-subtitle">{{ userName }}</strong>
                    <div
                      v-for="submenuRoute in filterItemMenu(route.children)"
                      :key="submenuRoute.name"
                    >
                      <a
                        v-if="submenuRoute.name == 'termsOfUse'"
                        href="#"
                        class="navbar-item"
                        @click="openTerms"
                      >{{ submenuRoute.title }}</a>

                      <a
                        v-else-if="submenuRoute.name == 'tutorial'"
                        href="#"
                        class="navbar-item"
                        @click="openTutorial"
                      >{{ submenuRoute.title }}</a>

                      <a
                        v-else-if="verifyItemAction(submenuRoute) == 'logout'"
                        href="#"
                        class="navbar-item"
                        @click="logout"
                      >{{ submenuRoute.title }}</a>

                      <router-link
                        v-else-if="verifyAccess(submenuRoute)"
                        class="navbar-item"
                        :to="{name: submenuRoute.name}"
                      >
                        {{ submenuRoute.title }}
                      </router-link>
                    </div>
                  </div>
                </div>

                <router-link
                  v-if="verifyItemAction(route) == 'router'"
                  class="navbar-item"
                  :class="route.name == pageName ? 'active' : ''"
                  :to="{name: route.name}"
                >
                  {{ route.title }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        v-show="loadingSignalR"
        class="loading signalr"
      />
    </section>

    <section
      class="content main-section"
      :class="mainSectionClass"
    >
      <div v-show="!loadingContent">
        <slot name="mainContent" />

        <p class="app-version">
          {{ $app_version }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import Terms from "@components/shared/lila/Terms.vue";
import Tutorial from "@components/shared/lila/Tutorial.vue";
import PopupAlert from "@/components/PatientTicketList/LilaPopUpAlert.vue";

export default {
  components: {
    "lila-terms": Terms,
    "lila-tutorial": Tutorial,
    PopupAlert
  },
  props: {
    loadingContent: {
      required: false,
      type: Boolean,
      default: false
    },
    loadingSignalR: {
      required: false,
      type: Boolean,
      default: false
    },
    alertMessage: {
      required: false,
      type: Object,
      default: () => {
        return {
          message: "",
          type: "",
          show: false
        };
      }
    }
  },
  data() {
    return {
      pageName: "",
      institutionName: "",
      userName: "",
      userGender: "",
      activeModal: false,
      burgerActive: false
    };
  },
  computed: {
    itensMenu(){
      return this.$router.options.routes.filter(item => typeof item.title !== "undefined" && this.verifyAccess(item));
    },
    mainSectionClass(){
      let sectionClass = this.$hasDoctorBg.includes(this.pageName) ? "doctor-bg" : "section";

      if(this.loadingContent) sectionClass += " loading large ";

      return sectionClass;
    }
  },
  created(){
    
    this.userName = this.$Session.get(this.$userSessionName).professional.Name;
    this.institutionName = this.$Session.get(this.$userSessionName).professional.Institution.Name;
    this.userGender = this.$Session.get(this.$userSessionName).professional.Gender;
    
    this.pageName = this.$route.name;

  },
  methods: {
    verifyAccess(route){
      let action = this.$Utilities.hasProperty(route, "meta.access_control") ? route.meta.access_control : false;

      if(action === false) return true;

      return !this.$AccessControl.cannot(this.$Session.get(this.$userSessionName).profile, action);
    },
    verifyItemAction(itemMenu){
      return typeof itemMenu.action !== "undefined" ? itemMenu.action : "router";
    },
    filterItemMenu(itemMenu){
      return itemMenu.filter(item => typeof item.title != "undefined");
    },
    openTerms(){
      this.activeModal = !this.activeModal;
    },
    openTutorial(){
      this.$refs.tutorial.openModal();
    },
    logout(){
      this.$Session.remove(this.$userSessionName);

      this.$router.push({name: "login"});
    },
    toggleBurger() {
      this.burgerActive = !this.burgerActive;
    },
    selectLogo() {

      let institutionId = this.$Session.get(this.$userSessionName).professional.Institution.Id;
      if (institutionId == 165)
        return "logo-cuidando.jpg";
      else
        return "logo-lila.png";
    },
    getImagePath(fileName) {
        return require(`@/assets/images/${fileName}`);
  }
  }
};
</script>

<style>
    @import '~@assets/css/main.css';
</style>
