<template>
  <div class="default-modal lila-transfer-ticket">
    <div
      class="modal"
      :class="{ 'is-active': modalTransferToProfessionalActive }"
    >
      <form @submit.prevent="changeProfessional()">
        <div class="modal-background" />
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Transferir para outro profissional
            </p>
          </header>
          <section class="modal-card-body">
            <div>
              <lila-checkbox
                :model-value="selectAll"
                :title="selectAll ? 'Desmarcar todos' : 'Selecionar todos'"
                @change="toggleSelectAll"
              />
            </div>
            <div
              v-for="professional in professionalList"
              :key="professional._id"
            >
              <lila-checkbox
                v-model="professional.selected"
                :value="professional._id"
                :title="professional.name"
              />
            </div>
          </section>
          <footer class="modal-card-foot">
            <lila-button
              type="submit"
              title="Confirmar"
              class="button"
              :is-centralized="true"
              :second-button="{ type: 'button', title: 'Cancelar' }"
              @customClickCancelEvent="closeTransferToProfessionalModal()"
            />
          </footer>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { defineProps, ref, onMounted } from "vue";
import LilaButton from "../shared/button/Button.vue";
import LilaCheckbox from "../shared/fields/Checkbox.vue";
import { Utilities } from "../../plugins/Utilities";
import axios from "../../js/axios";
import { Session } from "../../plugins/Session";
import { userSessionName } from "../../js/axios";
import { eventBus } from "../../eventBus";

const router = useRouter();

const p = defineProps({
  modalTransferToProfessionalActive: null,
  closeTransferToProfessionalModal: {
    type: Function,
    required: true
  },
  alert: {
    message: null,
    type: null,
    show: false
  },
  patientId: null,
  userId: null,
  ticketId: null
});

const professionalList = ref([]);
const selectAll = ref(false);

async function getProfessional() {
  try {
    const res = await axios.get(
      `/api/patient/${p.patientId}/attendingProfessionals`,
      {
        headers: {
          Authorization: `bearer ${Session.get(userSessionName).token.Value}`
        }
      }
    );

    professionalList.value = res.data
      .filter(item => item.Id !== p.userId)
      .map(item => {
        return {
          _id: item.Id,
          name: item.Name
        };
      });

  } catch (error) {
    if (error.response && error.response.status) {
      Utilities.verifyToken(error.response.status);
      if (error.response.status !== 404) {
        Utilities.controlAlert(
          error.response.data,
          p.alert,
          "danger"
        );
      }
    } else {
      console.error(error);
    }
  }
}

async function redirectToHome() {
  router.push("/chamados_pendentes");
}

async function removeTicket(){
  eventBus.emit("removeTicket", p.patientId);
}


async function changeProfessional() {
  try {
    const selectedProfessionalIds = professionalList.value
      .filter(professional => professional.selected)
      .map(professional => professional._id);


    await axios.post(
      `/api/ticket/${p.ticketId}/assignees`,
      { assignees: selectedProfessionalIds },
      {
        headers: {
          Authorization: `bearer ${Session.get(userSessionName).token.Value}`
        }
      }
    );

    professionalList.value.forEach(professional => {
      professional.selected = false;
    });

    p.closeTransferToProfessionalModal();

    Utilities.controlAlert(null, p.alert, "success", "Profissionais atribuídos com sucesso.");

    redirectToHome();

    removeTicket();

  } catch (error) {
    console.error(error);
    if (error.response) {
      Utilities.verifyToken(error.response.status);
      Utilities.controlAlert(error.response.data, p.alert, "danger");
    } else if (error.request) {
      Utilities.controlAlert(null, p.alert, "danger", "O servidor não responde");
    } else {
      Utilities.controlAlert(error.message, p.alert, "danger");
    }
  }
}

function toggleSelectAll() {
  selectAll.value = !selectAll.value;
  professionalList.value.forEach(professional => {
    professional.selected = selectAll.value;
  });
}

onMounted(() => {
  getProfessional();
});
</script>

<style>
.lila-transfer-ticket .modal-card .modal-card-body {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lila-transfer-ticket .modal-card .modal-card-body div {
  border-top: 1px solid #DFDFDF;
  padding: 3px 10px;
}

.lila-transfer-ticket .modal-card .modal-card-body div:first-child {
  border-top: none;
}

.lila-transfer-ticket .modal-card .modal-card-body .checkbox-container {
  font-size: 14px;
}

.lila-transfer-ticket .modal-card .modal-card-body .checkbox-container .checkbox-title {
  margin-left: 10px;
}

.lila-transfer-ticket .modal-card .modal-card-body .checkbox-component {
  border-bottom: 1px solid #DFDFDF;
}

.lila-transfer-ticket .modal-card .modal-card-body .checkbox-component:last-child {
  border-bottom: none;
}
</style>
