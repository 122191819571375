<template>
  <div class="patient-summary">
    <div class="avatar">
      <figure class="image is-64x64">
        <div
          v-show="!patient.imgLoaded"
          class="loading loading-profile-picture-64x64"
        />

        <div
          v-show="patient.imgLoaded"
          class="is-profile-picture-64x64"
        >
          <img
            :src="patient.profilePicture"
            @load="loaded"
          >
        </div>
      </figure>

      <div class="patient-info">
        <p><strong>{{ patient.name }}</strong></p>

        <div
          v-show="patient.ticketId != null"
          class="urgency-rating"
        >
          <p
            class="rating"
            :class="urgencyRatingClass ? urgencyRatingClass : patient.doctorPriority"
          >
            {{ urgencyRatingName ? urgencyRatingName : getPatientUrgency }}
          </p>
          <button
            type="button"
            class="borderless"
            @click="openReclassifyUrgencyModal()"
          >
            Reclassificar Urgência?
          </button>
        </div>
      </div>
    </div>
      
    <div>
      <p>{{ $Utilities.getAbbrevGender(patient.gender) }}{{ patient.birthDate ? `, ${$Utilities.getAge(patient.birthDate)}` : '' }}</p>
    </div>

    <div class="has-text-centered">
      <p><strong>{{ patient.cid }}</strong></p>
    </div>

    <div class="has-text-centered">
      <p v-show="patient.painLevel">
        Dor: <strong>{{ patient.painLevel }}</strong>
      </p>
      <p>
        <strong>{{ patient.doctor }}</strong>
      </p>
    </div>
  </div>

  <div class="reclassify-urgency-modal default-modal">
    <div
      class="modal"
      :class="modalReclassifyUrgencyActive ? 'is-active' : ''"
    >
      <form @submit.prevent="reclassifyUrgency()">
        <div class="modal-background" />

        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Reclassificar Urgência?
            </p>
          </header>

          <section class="modal-card-body">
            <ul>
              <li>
                <label class="custom-radio">
                  <input
                    v-model="tempDoctorPriority"
                    type="radio"
                    name="urgencyClassification"
                    value="High"
                  >
                  <span class="radio-title rating urgent">Urgente</span>
                  <span class="checkmark" />
                </label>
              </li>
              <li>
                <label class="custom-radio">
                  <input
                    v-model="tempDoctorPriority"
                    type="radio"
                    name="urgencyClassification"
                    value="Normal"
                  >
                  <span class="radio-title rating moderate">Moderada</span>
                  <span class="checkmark" />
                </label>
              </li>
              <li>
                <label class="custom-radio">
                  <input
                    v-model="tempDoctorPriority"
                    type="radio"
                    name="urgencyClassification"
                    value="Low"
                  >
                  <span class="radio-title rating low">Baixa</span>
                  <span class="checkmark" />
                </label>
              </li>
            </ul>
          </section>

          <footer class="modal-card-foot">
            <lila-button
              type="submit"
              title="Salvar"
              class="button"
              :loading="changingUrgency"
              :is-centralized="true"
              :second-button="{type: 'button', title: 'Cancelar'}"
              @customClickCancelEvent="closeReclassifyUrgencyModal()"
            />
          </footer>
        </div>
      </form>
    </div> 
  </div>
</template>

<script>
import { eventBus } from "../../../eventBus.js";
import { ref, provide, onMounted } from "vue";
import LilaButton from "../button/Button.vue";

export default {
  components: {
    LilaButton
  },
  props: {
    patient: {
      required: true,
      type: Object
    }
  },
  setup(props){
    const changingUrgency = ref(false);
    const modalReclassifyUrgencyActive = ref(false);
    const tempDoctorPriority = ref("");
    const alert = ref({
      message: "",
      type: "",
      show: false
    });

    provide("alert-message", alert);

    function openTicket(ticketId){
      if(ticketId) {
        props.patient.ticketId = ticketId;
        props.patient.doctorPriority = "moderate";
      }
    }

    onMounted(() => {
      eventBus.on("openTicket", openTicket);
    });


    return { changingUrgency, modalReclassifyUrgencyActive, tempDoctorPriority, alert };
  },
  computed: {
    urgencyRatingName(){
      switch (this.patient.doctorPriority) {
      case "High":
        return "Urgente";
      case "Normal":
        return "Moderado";
      case "Low":
        return "Baixa";
      default:
        return "";
      }
    },
    urgencyRatingClass(){
      switch (this.patient.doctorPriority) {
        case "High":
          return "urgent";
      case "Normal":
        return "moderate";
      case "Low":
        return "low";
      default:
        return "";
      }
    },
    getPatientUrgency(){
      switch (this.patient.doctorPriority) {
      case "urgent":
        return "Urgente";
      case "moderate":
        return "Moderado";
      case "low":
        return "Baixa";
      default:
        return "";
      }
    }
  },
  watch: {
    "patient": {
      handler: function(){
        this.getServiceLocation();
      }
    }
  },
  created(){
    this.getServiceLocation();
  },
  methods: {
    loaded(){
      this.patient.imgLoaded = true;
    },
    getPatient(patientId){
      this.$axios
        .get(
          `/api/patient/record/${patientId}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.Doctor) this.patient.doctor = res.data.Doctor.Name;
        });
    },
    getServiceLocation(){
      if(this.patient.doctor == undefined || this.patient.doctor == "") this.getPatient(this.patient.id);
    },
    reclassifyUrgency(){
      this.alert.show = false;
      this.changingUrgency = true;

      this.$axios({
        method: "PUT",
        url: `/api/chat/${this.patient.ticketId}`,
        data: {priority: this.tempDoctorPriority},
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          this.patient.doctorPriority = this.tempDoctorPriority;

          this.updateTicketPriority(this.patient.doctorPriority, this.patient.id);
        })
        .catch(error => {
          console.error(error);
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => {
          this.changingUrgency = false;
          this.closeReclassifyUrgencyModal();
        });
    },
    openReclassifyUrgencyModal(){
      this.modalReclassifyUrgencyActive = true;
    },
    closeReclassifyUrgencyModal(){
      this.tempDoctorPriority = this.patient.doctorPriority;
      this.modalReclassifyUrgencyActive = false;
    },
    updateTicketPriority(priority, patientId) {
      let payload = {
        priority: priority,
        patientId: patientId
      };
      eventBus.emit("updateTicketPriority", payload);
    }
  }
};
</script>

<style>
  @import '~@assets/css/components/patientCardSummary.css';
</style>
