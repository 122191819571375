<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Complete seu Cadastro
        </h1>

        <lila-message :alert-data="alert" />

        <div class="columns">
          <div class="column is-5">
            <div class="field-group">
              <div class="field-group-header align-title">
                <p>Dados</p>
              </div>

              <div class="field-group-body">
                <lila-input
                  v-model="v.data.crm.$model"
                  placeholder="CRM"
                  :validation="v.data.crm"
                />

                <lila-select
                  v-model="v.data.ufcrm.$model"
                  placeholder="UF"
                  :options="stateList"
                  :validation="v.data.ufcrm"
                />

                <lila-input
                  v-model="v.data.name.$model"
                  placeholder="Nome"
                  :validation="v.data.name"
                />

                <lila-input
                  v-model="v.data.email.$model"
                  placeholder="E-mail"
                  :validation="v.data.email"
                />

                <lila-mask
                  v-model="v.data.phone.$model"
                  placeholder="Telefone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :validation="v.data.phone"
                />

                <p class="obs">
                  *Caso deseje alterar seu email, você receberá uma mensagem no email original para confirmar a alteração.
                </p>
              </div>
            </div>
          </div>

          <div class="column is-2 middle-column">
            <div class="line" />
          </div>

          <div class="column is-5">
            <div class="field-group field-group-medical-care-locations">
              <div class="field-group-header">
                <p>Locais de atendimento</p>
              </div>

              <div class="field-group-body">
                <p>Registrar seus locais de atendimento vai te ajudar a identificar mais facilmente seus pacientes.</p>
              </div>

              <lila-input
                v-model="medicalCareLocation.name"
                type="inputButton"
                placeholder="Digite aqui o local"
                button-title="Adicionar"
                @customButtonClickEvent="addMedicalCareLocation"
              />

              <div class="medical-care-locations">
                <p>Locais de Atendimento adicionados:</p>

                <div class="medical-care-list">
                  <ul>
                    <li
                      v-for="location in medicalCareLocation.list"
                      :key="location.Street"
                    >
                      {{ location.Street }}
                      <lila-svg-icon
                        :src="require(`@assets/icons/delete.svg`)"
                        @customClickEvent="removeMedicalCareLocation(location.Street)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <lila-button
          type="submit"
          title="Salvar"
          class="submit-buttons"
          :loading="loading"
        />
      </form>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";

import { required, email } from "@vuelidate/validators";

import StateOptions from "@assets/options/states.json";

const verifyLastName = function(value){
  let name = value.trim().replace(/\s\s+/g, " ").split(" ");

  if(typeof name[1] == "undefined" || (typeof name[1] != "undefined" && name[1].length < 1)) return false;

  return true;
};

export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-svg-icon": SvgIcon,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate,
    "lila-mask": LilaMask
  },
  data() {
    return {
      id: this.$Session.get(this.$userSessionName).doctor.Id,
      data: {
        crm: this.$Session.get(this.$userSessionName).doctor.CRM,
        ufcrm: this.$Session.get(this.$userSessionName).doctor.UFCRM,
        name: this.$Session.get(this.$userSessionName).doctor.Name,
        email: this.$Session.get(this.$userSessionName).doctor.Email,
        phone: this.$Session.get(this.$userSessionName).doctor.Phone
      },
      medicalCareLocation: {
        name: "",
        list: this.$Session.get(this.$userSessionName).doctor.Addresses[0] ? this.$Session.get(this.$userSessionName).doctor.Addresses : [],
        deleted: []
      },
      stateList: StateOptions.options,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        crm: {required},
        ufcrm: {required},
        name: {
          required,
          verifyLastName
        },
        email: {
          required,
          email
        },
        phone: {
          required
        }
      }
    };
  },
  created(){
    this.$Utilities.checkFullRegistration();
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      let requestList = [
        this.saveBasicInformation(),
        this.saveMedicalCareLocation()
      ];

      this.medicalCareLocation.deleted.forEach(item => requestList.push(this.deleteMedicalCareLocation(item)));

      Promise
        .all(requestList)
        .then(() => this.$router.push({name: "complementaryInformation", params: {message: "basicInformation"}}))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    saveBasicInformation(){
      return this.$axios({
        method: "PUT",
        url: "api/doctor",
        data: this.data,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          this.$Session.setUserAttribute("CRM", this.data.crm);
          this.$Session.setUserAttribute("UFCRM", this.data.ufcrm);
          this.$Session.setUserAttribute("Name", this.data.name);
          this.$Session.setUserAttribute("Email", this.data.email);
          this.$Session.setUserAttribute("Phone", this.data.phone);
        });
    },
    saveMedicalCareLocation(){
      let locationList = this.medicalCareLocation.list.filter(item => typeof item.Id == "undefined");

      if(locationList.length == 0) return;

      return this.$axios({
        method: "POST",
        url: "api/address",
        data: locationList,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(res => {
          let locationList = res.data;

          this.medicalCareLocation.list.forEach(item => {
            if(typeof item.Id != "undefined") locationList.push(item);
          });

          this.$Session.setUserAttribute("Addresses", locationList);
        });
    },
    deleteMedicalCareLocation(locationId){
      return this.$axios({
        method: "DELETE",
        url: `api/address/${locationId}`,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      });
    },
    addMedicalCareLocation(){
      if(this.medicalCareLocation.name.trim() && this.medicalCareLocation.list.indexOf(this.medicalCareLocation.name) == -1){
        this.medicalCareLocation.list.push({Street: this.medicalCareLocation.name});
        this.medicalCareLocation.name = "";
      }
    },
    removeMedicalCareLocation(location){
      this.medicalCareLocation.list.forEach((item, index) => {
        if(item.Street == location){
          if(typeof item.Id != "undefined") this.medicalCareLocation.deleted.push(item.Id);

          this.medicalCareLocation.list.splice(index, 1);
        }
      });
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';
</style>
