<template>
  <div class="historyRecord">
    <lila-message :alert-data="alert" />

    <div
      class="field-group history-record-container"
      :class="[loadingContent ? 'loading large' : '', loadingContent || data.historyRecordList.length > 0 ? 'history-record-container-default-height' : '']"
    >
      <div
        v-show="!loadingContent"
        class="field-group-header align-title"
      >
        <p
          v-show="data.historyRecordList.length == 0"
          class="semi-bold-text"
        >
          Você ainda não incluiu nenhum Registro.
        </p>
      </div>

      <div
        v-show="!loadingContent"
        class="field-group-body"
      >
        <lila-history-record-card
          :data-list="data.historyRecordList"
          :extended-description="true"
          card-size="is-12-desktop"
          @customClickEditEvent="mountEditHistoryRecord"
          @customClickDeleteEvent="openDeleteModal"
        />
      </div>
    </div>

    <lila-pagination
      v-show="!loadingContent && data.historyRecordList.length > 0"
      :pagination-config="pagination"
      @customChangePageEvent="changePage"
    />

    <form
      class="container basic-info-form medical-card-form history-record-form"
      @submit.prevent="submitForm()"
    >
      <div
        ref="historyRecordForm"
        class="field-group"
      >
        <div class="field-group-header align-title">
          <p class="field-group-label-type">
            Adicionar Registro
          </p>
        </div>

        <div class="field-group-body">
          <lila-select
            v-model="v.newHistoryRecord.historyRecordTypeId.$model"
            placeholder="Escolha o tipo do Registro"
            :options="historyRecordTypeList"
            :validation="v.newHistoryRecord.historyRecordTypeId"
            @customChangeEvent="changeHistoryRecordType"
          />

          <div class="columns">
            <div class="column is-6">
              <lila-datepicker
                v-show="newHistoryRecord.historyRecordTypeId != 4"
                v-model="v.newHistoryRecord.initialDate.$model"
                :placeholder="newHistoryRecord.historyRecordTypeId == 1 ? 'Data' : 'Data inicio'"
                :validation="v.newHistoryRecord.initialDate"
              />
            </div>

            <div class="column is-6">
              <lila-datepicker
                v-show="newHistoryRecord.historyRecordTypeId != 1 && newHistoryRecord.historyRecordTypeId != 4"
                v-model="v.newHistoryRecord.endDate.$model"
                placeholder="Data fim"
                :validation="v.newHistoryRecord.endDate"
              />
            </div>
          </div>

          <lila-textarea
            v-show="newHistoryRecord.historyRecordTypeId != 4"
            v-model="v.newHistoryRecord.historyRecordNotes.$model"
            placeholder="Anotações do Registro"
            :validation="v.newHistoryRecord.historyRecordNotes"
          />

          <lila-button
            type="submit"
            :title="newHistoryRecord.id ? 'Editar' : 'Adicionar'"
            :second-button="newHistoryRecord.id ? {type: 'button', title: 'Cancelar'} : {}"
            :loading="loading"
            @customClickCancelEvent="clearHistoryRecord()"
          />
        </div>
      </div>
    </form>

    <div
      class="modal default-modal"
      :class="deleteModal.active ? 'is-active' : ''"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Excluir
          </p>
        </header>

        <section class="modal-card-body">
          <p>Deseja excluir esse registro?</p>
        </section>

        <footer class="modal-card-foot">
          <lila-button
            type="submit"
            title="Excluir"
            class="submit-buttons"
            :second-button="{type: 'button', title: 'Cancelar', class: 'dark-lilas'}"
            @customClickEvent="deleteHistoryRecord(deleteModal.deleted)"
            @customClickCancelEvent="closeDeleteModal()"
          />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@components/shared/fields/Select.vue";
import Textarea from "@components/shared/fields/Textarea.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";
import HistoryRecordCard from "@components/shared/lila/HistoryRecordCard.vue";
import Pagination from "@components/shared/pagination/Pagination.vue";
import LilaDatepicker from "@components/shared/fields/LilaDatepicker.vue";

import { requiredIf } from "@vuelidate/validators";

export default {
  components: {
    LilaDatepicker,
    "lila-select": Select,
    "lila-textarea": Textarea,
    "lila-button": Button,
    "lila-message": Message,
    "lila-history-record-card": HistoryRecordCard,
    "lila-pagination": Pagination
  },
  data(){
    return {
      data: {
        id: this.$route.params.id,
        historyRecordList: []
      },
      newHistoryRecord: {
        addNew: false,
        id: "",
        historyRecordTypeId: "",
        initialDate: "",
        endDate: "",
        historyRecordNotes: ""
      },
      historyRecordTypeList: [],
      deleteModal: {
        active: false,
        deleted: null
      },
      loadingContent: false,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      },
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 10
      }
    };
  },
  validations() {
    if(this.newHistoryRecord.addNew){
      return {
        newHistoryRecord: {
          historyRecordTypeId: {
              required: requiredIf(function(){
                  return this.newHistoryRecord.addNew;
              })
          }, 
          initialDate: {},
          endDate: {},
          historyRecordNotes: {}
        }
      };
    } else {
      return {
        newHistoryRecord: {
          historyRecordTypeId: {
              required: requiredIf(function(){
                  return this.newHistoryRecord.addNew;
              })
          }, 
          initialDate: {},
          endDate: {},
          historyRecordNotes: {}
        }
      };
    }
  },
  watch:{
    $route (to/*, from*/){
      this.data.id = to.params.id;

      this.getContent();
    }
  },
  created(){
    this.getContent();
  },
  methods: {
    submitForm(){
      this.newHistoryRecord.addNew = true;

      this.v.$touch();

      if(this.v.$invalid) return;

      if(this.newHistoryRecord.id){
        this.editHistoryRecord(Object.assign({}, this.newHistoryRecord));
      }else{
        this.saveHistoryRecord(Object.assign({}, this.newHistoryRecord));
      }
    },
    getContent(){
      this.loadingContent = true;

      Promise
        .all([
          this.getPatientHistoryRecordList(),
          this.getHistoryRecordType()
        ])
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$router.push({name: "myPatients"});
        })
        .finally(() => this.loadingContent = false);
    },
    getPatientHistoryRecordList(page = 1){
      return this.$axios
        .get(
          `api/historyRecord/patient/${this.data.id}?page=${page}&limit=${this.pagination.perPage}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.pagination.current = res.data.Pagination.CurrentPage;
          this.pagination.totalPages = res.data.Pagination.TotalPage;
          this.pagination.perPage = res.data.Pagination.ItemPerPage;

          // Ao montar a lista, usa a função formatData para cada item
          // Lembrando que esse "item" passado como parâmetro é um objeto vindo da resposta HTTP da API, sem nenhuma manipulação feita no código aqui.
          this.data.historyRecordList = res.data.Data.map(item => this.formatData(item));
        });
    },
    getHistoryRecordType(){
      return this.$axios
        .get(
          "api/historyRecordtype",
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.historyRecordTypeList = res.data.map(item => {
            return {
              _id: item.Id,
              name: item.Name
            };
          });
        });
    },
    changePage(page, keepAlert = false){
      if(!keepAlert) this.alert.show = false;
      this.loadingContent = true;

      Promise
        .all([
          this.getPatientHistoryRecordList(page)
        ])
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    saveHistoryRecord(historyRecord){
      this.loading = true;

      this.$axios({
        method: "POST",
        url: `api/historyRecord/patient/${this.data.id}`,
        data: this.formatDataToSend(historyRecord),
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then((res) => {
          this.data.historyRecordList.push(this.formatData(res.data));
          this.changePage(this.pagination.current);
          this.clearHistoryRecord();

          this.$Utilities.controlAlert(null, this.alert, "save");
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    editHistoryRecord(historyRecord){
      this.loading = true;

      this.$axios({
        method: "PUT",
        url: `api/historyRecord/${historyRecord.id}/patient/${this.data.id}`,
        data: this.formatDataToSend(historyRecord),
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          let index = this.data.historyRecordList.findIndex(item => item.id == historyRecord.id);
          if(index != -1){
            this.data.historyRecordList[index].historyRecordTypeId = historyRecord.historyRecordTypeId;
            this.data.historyRecordList[index].historyRecordTypeName = this.getHistoryRecordByName(historyRecord.historyRecordTypeId);
            this.data.historyRecordList[index].date = `${historyRecord.initialDate} ${historyRecord.endDate ? `- ${historyRecord.endDate}` : ""}`;
            this.data.historyRecordList[index].initialDate = historyRecord.initialDate;
            this.data.historyRecordList[index].endDate = historyRecord.endDate;
            this.data.historyRecordList[index].historyRecordNotes = historyRecord.historyRecordNotes;
          }

          this.$Utilities.controlAlert(null, this.alert, "edit");
          this.clearHistoryRecord();
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    deleteHistoryRecord(deletedHistoryRecord){
      this.alert.show = false;

      deletedHistoryRecord.deleteLoading = true;

      this.closeDeleteModal();

      this.$axios
        .delete(
          `/api/historyRecord/${deletedHistoryRecord.id}/patient/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then(() => {
          this.data.historyRecordList = this.data.historyRecordList.filter(item => item.id != deletedHistoryRecord.id);
          this.changePage(this.pagination.current, true);

          this.$Utilities.controlAlert(null, this.alert, "delete");
        })
        .catch(error => {
          deletedHistoryRecord.deleteLoading = false;

          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        });
    },
    // Recebe um objeto "HistoryRecord" (a ser renomeado para History Record) da API como JSON
    // Cria um objeto "date" com duas strings "initial" e "end" armazenando como string as datas iniciais e finais do HistoryRecord]
    // Devolve um objeto semelhante ao HistoryRecord, aparentemente pronto para ser consumido pelo front, já que foi feita esse jogo com as datas para strings em um único campo "date" nesse objeto retornado.
    formatData(historyRecordAsJSON){
      let date = {
        initial: historyRecordAsJSON.InitialDate ? `${this.$Utilities.formatDate(historyRecordAsJSON.InitialDate, "YYYY-MM-DD", "DD/MM/YYYY", true)} ` : "",
        end: historyRecordAsJSON.EndDate ? ` - ${this.$Utilities.formatDate(historyRecordAsJSON.EndDate, "YYYY-MM-DD", "DD/MM/YYYY", true)}` : ""
      };

      // Esse é o objeto HistoryRecord usado localmente no Vue, com suas adaptações e modificações para o frontend
      let historyRecord = {
        id: historyRecordAsJSON.Id,
        historyRecordTypeId: historyRecordAsJSON.HistoryRecordType.Id,
        historyRecordTypeName: historyRecordAsJSON.HistoryRecordType.Name,
        date: date.initial + date.end,
        initialDate: date.initial,
        endDate: date.end,
        historyRecordNotes: historyRecordAsJSON.HistoryRecordNotes,
        deleteLoading: false
      };

      return historyRecord;
    },

    // Recebe um "HistoryRecord" do front
    // Faz um pequeno tratamento nas datas
    // Retorna um objeto "JSON" pronto para ser enviado à API
    formatDataToSend(historyRecord){
      // Esse objeto será enviado para a API e deve atender à interface da API, ou seja, respeitar o nome dos membros que estão na API de alguma forma
      
      // Nomes "HistoryRecord" mantidos para respeitar a API que ainda não foi alterada
      let historyRecordAsJSON = {
        HistoryRecordTypeId: historyRecord.historyRecordTypeId,
        HistoryRecordNotes: historyRecord.historyRecordNotes,
        InitialDate: undefined,
        EndDate: undefined
      };

      if(historyRecord.initialDate) {
        historyRecordAsJSON.InitialDate = this.$Utilities.formatDate(historyRecord.initialDate, "DD/MM/YYYY", "MM/DD/YYYY");
      } 
      
      if(historyRecord.endDate) {
        historyRecordAsJSON.EndDate = this.$Utilities.formatDate(historyRecord.endDate, "DD/MM/YYYY", "MM/DD/YYYY");
      }

      return historyRecordAsJSON;
    },
    mountEditHistoryRecord(historyRecord){
      this.$refs.historyRecordForm.scrollIntoView();

      this.newHistoryRecord.id = historyRecord.id;
      this.newHistoryRecord.historyRecordTypeId = historyRecord.historyRecordTypeId;

      this.newHistoryRecord.initialDate = historyRecord.initialDate;
      this.newHistoryRecord.endDate = historyRecord.endDate;
      this.newHistoryRecord.historyRecordNotes = historyRecord.historyRecordNotes;
    },
    clearHistoryRecord(){
      this.newHistoryRecord.addNew = false;
      this.newHistoryRecord.id = "";
      this.newHistoryRecord.historyRecordTypeId = "";
      this.newHistoryRecord.historyRecordTypeName = "";
      this.newHistoryRecord.date = "";
      this.newHistoryRecord.initialDate = "";
      this.newHistoryRecord.endDate = "";
      this.newHistoryRecord.historyRecordNotes = "";

      this.v.$reset();
    },
    getHistoryRecordByName(historyRecordId){
      let historyRecord = this.historyRecordTypeList.filter(item => item._id == historyRecordId);

      return historyRecord.length > 0 ? historyRecord[0].name : "- - -";
    },
    changeHistoryRecordType(historyRecordTypeId){
      if(historyRecordTypeId == 1) this.newHistoryRecord.endDate = "";
      if(historyRecordTypeId == 4) {
        this.newHistoryRecord.date = "";
        this.newHistoryRecord.initialDate = "";
        this.newHistoryRecord.endDate = "";
        this.newHistoryRecord.historyRecordNotes = "";
      }
    },
    openDeleteModal(deletedHistoryRecord){
      this.deleteModal.active = true;
      this.deleteModal.deleted = deletedHistoryRecord;
    },
    closeDeleteModal(){
      this.deleteModal.active = false;
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/historyRecord.css';
</style>
