import Axios from "axios";

export const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_WEB
});

export const userSessionName = "loggedClientLilaMedico";

export const apiApp = process.env.VUE_APP_API_APP;

axios.defaults.withCredentials = true;

export const signalApi = process.env.VUE_APP_SIGNAL_API;

export default axios;