<template>
  <div
    class="messages chat chat-history"
    :class="loadingContent ? 'loading large' : ''"
  >
    <lila-message :alert-data="alert" />

    <div v-show="!loadingContent">
      <div class="attached-documents">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th @click="orderColumn('Date')">
                <span
                  :class="[
                    sortingControl.column == 'Date' ? 'arrow' : '',
                    sortingControl.order ? 'active' : '']"
                >Data</span>
              </th>
              <th @click="orderColumn('Origin')">
                <span
                  :class="[
                    sortingControl.column == 'Origin' ? 'arrow' : '',
                    sortingControl.order ? 'active' : '']"
                >Enviado por</span>
              </th>
              <th @click="orderColumn('FileExtension')">
                <span
                  :class="[
                    sortingControl.column == 'FileExtension' ? 'arrow' : '',
                    sortingControl.order ? 'active' : '']"
                >Arquivos</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in data.documentsList"
              :key="item.Id"
            >
              <td class="attachment-time">
                <time>{{ $Utilities.formatDateWithTime(item.DateAndTime, 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}</time>
              </td>
              <td class="attachment-sent-by">
                Enviado pelo {{ item.Origin == 'Doctor' ? 'Equipe de Saúde' : 'Paciente' }}
              </td>
              <td>
                <a
                  v-if="$Utilities.getFileExtension(item.AttachedDocument) == 'pdf'"
                  :href="item.AttachedDocument"
                  target="_blank"
                >
                  <lila-svg-icon :src="require(`@assets/icons/pdf_download.svg`)" />
                </a>

                <a
                  v-else
                  :href="item.AttachedDocument"
                  target="_blank"
                >
                  <figure class="image">
                    <img :src="item.AttachedDocument">
                  </figure>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <lila-pagination
          :pagination-config="pagination"
          @customChangePageEvent="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";
import Pagination from "@components/shared/pagination/Pagination.vue";

export default {
  components: {
    "lila-svg-icon": SvgIcon,
    "lila-message": Message,
    "lila-pagination": Pagination
  },
  data(){
    return {
      data: {
        patientId: this.$route.params.id,
        documentsList: []
      },
      sortingControl: {
        column: "",
        order: true
      },
      loadingContent: false,
      alert: {
        message: "",
        type: "",
        show: false
      },
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 10
      }
    };
  },
  created(){
    this.getAttachedDocumentsList();
  },
  methods: {
    getAttachedDocumentsList(page = 1){
      this.alert.show = false;
      this.loadingContent = true;

      this.$axios
        .get(
          `/api/chat/attacheddocuments/patient/${this.data.patientId}?page=${page}&limit=${this.pagination.perPage}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.pagination.current = res.data.Pagination.CurrentPage;
          this.pagination.totalPages = res.data.Pagination.TotalPage;
          this.pagination.perPage = res.data.Pagination.ItemPerPage;

          this.data.documentsList = [];

          res.data.Data.forEach(item => {
            item.Messages.forEach(message => {
              message.Date = this.$moment(message.DateAndTime, "YYYY-MM-DDTHH:mm:ss").unix();
              message.FileExtension = this.$Utilities.getFileExtension(message.AttachedDocument) == "pdf" ? "pdf" : "image";

              this.data.documentsList.push(message);
            });
          });
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, error.response.status == 404 ? "warning" : "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    changePage(page){
      this.getAttachedDocumentsList(page);
    },
    orderColumn(column){
      if(this.sortingControl.column == column){
        this.sortingControl.order = !this.sortingControl.order;
      }else{
        this.sortingControl.column = column;
        this.sortingControl.order = true;
      }

      this.sortingControl.order ? this.sortColumnAsc(this.sortingControl.column) : this.sortColumnDesc(this.sortingControl.column);
    },
    sortColumnAsc(column){
      this.data.documentsList = this.data.documentsList.sort((firstRow, secondRow) => {
        if(firstRow[column] > secondRow[column]) return 1;
        if(firstRow[column] < secondRow[column]) return -1;
        return 0;
      });
    },
    sortColumnDesc(column){
      this.data.documentsList = this.data.documentsList.sort((firstRow, secondRow) => {
        if(firstRow[column] > secondRow[column]) return -1;
        if(firstRow[column] < secondRow[column]) return 1;
        return 0;
      });
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/chat.css';
</style>
