<template>
  <lila-default-template :loading-signal-r="loadingSignalR">
    <template #mainContent>
      <div
        class="container patient-list"
        :class="loadingContent ? 'loading large' : ''"
      >
        <h1 class="title">
          Pacientes
        </h1>

        <lila-message :alert-data="alert" />

        <div
          v-show="!loadingContent"
          class="columns"
        >
          <div class="column is-narrow patient-sidebar">
            <div
              ref="conversationList"
              class="conversation-list custom-scroll"
            >
              <div class="conversation-content">
                <div class="conversation-list-header patient-list-header">
                  <div :class="!$AccessControl.cannot($Session.get($userSessionName).profile, 'add_patient') ? 'box-input-search' : ''">
                    <lila-button
                      v-if="!$AccessControl.cannot($Session.get($userSessionName).profile, 'add_patient')"
                      type="route"
                      title="Adicionar"
                      class="submit-buttons"
                      path="step1PatientInformation"
                    />

                    <form @submit.prevent="filterBy('byNameOrRegisterNumber')">
                      <lila-input
                        v-model="search.byNameOrRegisterNumber"
                        type="inputSearch"
                        class="with-shadow"
                        placeholder="Pesquisar"
                        title="Insira o nome ou número de matrícula para pesquisar"
                        :readonly="loadingInfinityScroll"
                        :btn-clear-search="search.activateClearSearchBtn"
                        @customClearSearchInputEvent="clearFilterBy('byNameOrRegisterNumber')"
                      />
                    </form>
                  </div>

                  <div class="menu">
                    <ul>
                      <li
                        :class="filterByVisible ? 'active' : ''"
                        @click="openOptions('filterBy')"
                      >
                        Filtrar por
                      </li>
                    </ul>

                    <button
                      v-show="filterByVisible && (search.activeCid || search.activeEstadio)"
                      class="clear-filter"
                      :disabled="loadingInfinityScroll"
                      @click="clearFilterBy('byCidEstadio')"
                    >
                      <lila-svgIcon :src="require(`@assets/icons/delete.svg`)" /> Limpar Filtro
                    </button>
                  </div>

                  <div
                    v-show="orderByVisible"
                    class="order-by"
                  >
                    <lila-radio
                      label=""
                      name="orderBy"
                      :options="orderByList"
                    />
                  </div>

                  <div
                    v-show="filterByVisible"
                    class="filter-by"
                  >
                    <lila-input
                      v-model="search.cidCode"
                      placeholder="Selecionar CID"
                      class="filter-by-cid"
                      :readonly="true"
                      :disabled="loadingInfinityScroll"
                      @customClickEvent="openCidModal"
                    />

                    <lila-select
                      v-model="search.byEstadio"
                      placeholder="Selecionar PS"
                      :options="estadioList"
                      :readonly="loadingInfinityScroll"
                      @customChangeEvent="filterBy('byEstadio')"
                    />
                  </div>
                </div>

                <lila-patient-card
                  :data-list="data.patientList"
                  :selected-patient="data.selectedPatient"
                  router-path="myPatientChat"
                  @customClickEvent="selectPatient"
                />

                <div
                  v-show="loadingInfinityScroll"
                  class="loading small"
                />
                <div
                  v-show="pagination.lastPage"
                  class="last-page has-text-centered"
                >
                  - - -
                </div>
              </div>
            </div>
          </div>

          <div class="column patient-column">
            <div
              v-if="pageName == 'myPatients'"
              class="wait-patient has-text-centered"
            >
              <figure class="image">
                <img :src="require(`@assets/images/doctor.png`)">
              </figure>

              <p>Clique nos cards ao lado para interagir e para mais informações</p>
            </div>

            <div
              v-else
              ref="patientBox"
              class="patient-box"
            >
              <lila-patient-card-summary
                v-if="data.selectedPatient"
                :patient="data.selectedPatient"
              />

              <div class="menu-patient-card">
                <ul>
                  <li :class="$route.name == 'myPatientMedicalRecord' ? 'active' : ''">
                    <router-link :to="{name: 'myPatientMedicalRecord', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Ficha
                    </router-link>
                  </li>
                  <li :class="$route.name == 'myPatientHistoryRecords' ? 'active' : ''">
                    <router-link :to="{name: 'myPatientHistoryRecords', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Registros
                    </router-link>
                  </li>
                  <li
                    v-show="userHasPermission()"
                    :class="$route.name == 'mypatientPrescriptions' ? 'active' : ''"
                  >
                    <router-link :to="{name: 'mypatientPrescriptions', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Receita Médica
                    </router-link>
                  </li>
                  <li :class="$route.name == 'myPatientClinicalEvolution' ? 'active' : ''">
                    <router-link :to="{name: 'myPatientClinicalEvolution', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Evolução Clínica
                    </router-link>
                  </li>
                  <li :class="$route.name == 'myPatientChat' ? 'active' : ''">
                    <router-link :to="{name: 'myPatientChat', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Conversa
                    </router-link>
                  </li>
                  <li :class="$route.name == 'myPatientChatHistory' ? 'active' : ''">
                    <router-link :to="{name: 'myPatientChatHistory', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Conversas Encerradas
                    </router-link>
                  </li>
                  <li :class="$route.name == 'myPatientAttachments' ? 'active' : ''">
                    <router-link :to="{name: 'myPatientAttachments', id: data.selectedPatient ? data.selectedPatient.id : ''}">
                      Anexos
                    </router-link>
                  </li>
                </ul>
              </div>

              <router-view
                :closed-ticket-id="closedTicketId"
                :new-message-for-patient="newMessageForPatient"
                @updatePatient="updatePatient"
                @updatePatientCardSummary="updatePatientCardSummary"
              />
            </div>
          </div>
        </div>

        <lila-cid-modal
          :modal-active="cidModalActive"
          @customClickEvent="selectCid"
        />
      </div>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";
import { provide, ref } from "vue";
import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import Radio from "@components/shared/fields/Radio.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";
import PatientCard from "@components/shared/lila/PatientCard.vue";
import PatientCardSummary from "@components/shared/lila/PatientCardSummary.vue";
import CidModal from "@components/shared/lila/CidModal.vue";
import decodeToken from "../../js/decodeToken.js";
import PerformanceStatusOptions from "@assets/options/performanceStatus.json";
import EstadioOptions from "@assets/options/estadio.json";

require("@microsoft/signalr");

export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-radio": Radio,
    "lila-svgIcon": SvgIcon,
    "lila-patient-card": PatientCard,
    "lila-patient-card-summary": PatientCardSummary,
    "lila-message": Message,
    "lila-cid-modal": CidModal,
    "lila-default-template": DefaultTemplate
  },
  setup () {
    const alert = ref({
        message: "",
        type: "",
        show: false
    });
    provide("alert-message", alert);

    return { alert };
  },
  data() {
    return {
      userId: null,
      data: {
        selectedPatient: null,
        patientList: []
      },
      orderByVisible: false,
      filterByVisible: false,
      orderByList: [
        {"_id": 1, "name": "Nome do Paciente"},
        {"_id": 2, "name": "Data da última mensagem"}
      ],
      performanceStatusList: PerformanceStatusOptions.options,
      estadioList: EstadioOptions.options,
      search: {
        byNameOrRegisterNumber: "",
        byCid: "",
        byEstadio: "",
        activeNameOrRegisterNumber: false,
        activeCid: false,
        activeEstadio: false,
        activateClearSearchBtn: false
      },
      loadingSignalR: false,
      ticketSignalR: {
        active: false,
        connection: null,
        timeout: false,
        retryCount: 0
      },
      signalRTimeout: 5000,
      signalRMaxRetry: 3,
      newMessageForPatient: {},
      closedTicketId: null,
      cidModalActive: false,
      pageName: this.$route.name,
      loading: false,
      loadingContent: false,
      loadingInfinityScroll: false,
      firstLoading: true,
      patientColumnHeight: 0,
      patientColumnHeightInterval: null,
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 10,
        lastPage: false
      }
    };
  },
  watch:{
    $route (to){
      this.pageName = to.name;

      if(this.pageName == "myPatients") this.data.selectedPatient = null;
    }
  },
  created(){
    switch (this.$Session.get(this.$userSessionName).profile) {
    case "doctor":
      this.userId = this.$Session.get(this.$userSessionName).doctor.Id;
      break;
    default:
      this.userId = this.$Session.get(this.$userSessionName).professional.Id;
      break;
    }

    if (this.$route.query.customMessageType) {
      const data = {customMessage: {type:this.$route.query.customMessageType, message: this.$route.query.customMessage}};
      this.$Utilities.showResultMessage(data, this.alert);
    } else {
      this.$Utilities.showResultMessage(this.$route.query, this.alert);
    }

    this.getPatientList();

    this.startTicketSignalR();
  },
  mounted(){
    let $this = this;

    $this.$refs.conversationList.addEventListener("scroll", function() {
      $this.checkEndOfPage();
    });

    this.controlSidebarHeight();
  },
  beforeUnmount(){
    clearInterval(this.patientColumnHeightInterval);

    this.closeSignalRConnections();
  },
  methods: {
    getPatientList(page = 1, searchType = "", localLoading = false){
      if(this.loadingContent || this.loadingInfinityScroll || this.pagination.lastPage) return;

      let pagination = `?page=${page}&limit=${this.pagination.perPage}`;
      let params = this.createParameters();
      let apiEndpoint = params ? `api/patient/search${pagination}${params}` : `api/patient${pagination}`;

      if(!this.firstLoading) this.alert.show = false;

      if(params || localLoading || searchType != "" || page > 1){
        if(searchType != "") this.data.patientList = [];

        this.loadingInfinityScroll = true;

        this.scrollPatientListToBottom();
      }else{
        this.loadingContent = true;
      }

      this.$axios
        .get(
          apiEndpoint,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.firstLoading = false;

          if(res.data.Pagination.TotalPage == res.data.Pagination.CurrentPage) this.pagination.lastPage = true;

          this.pagination.current = res.data.Pagination.CurrentPage;
          this.pagination.totalPages = res.data.Pagination.TotalPage;

          this.data.patientList = this.data.patientList.concat(res.data.Data.map(item => {
            return {
              id: item.Id,
              name: item.Name,
              profilePicture: item.Photo ? item.Photo : this.$Utilities.getDefaultProfilePicture(item.Gender),
              cid: this.$Utilities.getPatientCid(item),
              gender: item.Gender,
              birthDate: item.BirthDate,
              diagnostic: item.Diagnostic,
              painLevel: "",
              doctor: item.Doctor.Name,
              serviceLocation: item.AttendancePlace ? item.AttendancePlace.Street : "",
              imgLoaded: false,
              ticketId: item.OpenTicket != null ? item.OpenTicket.Id : null,
              doctorPriority: item.OpenTicket != null ? item.OpenTicket.DoctorPriority : ""
            };
          }));

          if(this.$route.params.id){
            this.getSelectedPatient(this.$route.params.id);

            if(this.data.selectedPatient == null) this.$router.push({name: "myPatients"});
          }

          if(this.data.patientList.length == 0) this.$Utilities.controlAlert(null, this.alert, "emptyList");
        })
        .catch(error => {
          console.error(error);
          this.pagination.lastPage = true;

          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => {
          this.loadingContent = false;
          this.loadingInfinityScroll = false;
        });
    },
    getPatientPain(patientId){
      this.$axios
        .get(
          `/api/clinicalevolution/pain/patient/${patientId}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data) this.data.selectedPatient.painLevel = `EVA ${res.data.Evolutions[0].Intensity}`;
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
        })
        .finally(() => this.loadingContent = false);
    },
    scrollPatientListToBottom(){
      setTimeout(function($this){
        $this.$refs.conversationList.scrollTo(0, $this.$refs.conversationList.scrollHeight);
      }, 50, this);
    },
    openOptions(option){
      switch (option) {
      case "orderBy":
        this.orderByVisible = !this.orderByVisible;
        this.filterByVisible = false;
        break;

      case "filterBy":
        this.filterByVisible = !this.filterByVisible;
        this.orderByVisible = false;
        break;
      }
    },
    selectPatient(patient){
      let selectedPatient = Object.assign({}, patient);

      if(this.data.selectedPatient && this.data.selectedPatient.profilePicture == selectedPatient.profilePicture){
        selectedPatient.imgLoaded = true;
      }

      this.data.selectedPatient = selectedPatient;
      
      this.getPatientPain(this.data.selectedPatient.id);

    },
    getSelectedPatient(patientId){
      let patient = this.data.patientList.filter(item => item.id == patientId);

      if(patient.length > 0) this.selectPatient(patient[0]);
    },
    updatePatient(patient){
      for(let index = 0; index < this.data.patientList.length; index++){
        if(this.data.patientList[index].id == this.data.selectedPatient.id){
          this.data.patientList[index].name = patient.name;
          this.data.patientList[index].cid = this.getSelectedPatientCid(patient);
          this.data.patientList[index].gender = patient.gender,
          this.data.patientList[index].birthDate = patient.birthdate ? this.$Utilities.formatDate(patient.birthdate, "DD/MM/YYYY", "MM/DD/YYYY") : "",
          this.data.patientList[index].diagnostic = patient.diagnostic;

          break;
        }
      }
    },
    updatePatientCardSummary(patient) {
      this.data.selectedPatient.name = patient.name;
      this.data.selectedPatient.cid = this.getSelectedPatientCid(patient);
      this.data.selectedPatient.gender = patient.gender,
      this.data.selectedPatient.birthDate = patient.birthdate ? this.$Utilities.formatDate(patient.birthdate, "DD/MM/YYYY", "MM/DD/YYYY") : "",
      this.data.selectedPatient.diagnostic = patient.diagnostic;
    },
    getSelectedPatientCid(patient){
      let activeCidList = (patient.cidList[0] != null) ? patient.cidList.filter(item => item.isEnable) : [];

      if(activeCidList.length == 0)  return this.getPatientPs(patient.ps);
      if(activeCidList.length == 1) return `${activeCidList[0].cidName} . ${this.getPatientEstadio(activeCidList[0].estadio)} . ${this.getPatientPs(patient.ps)}`;
      if(activeCidList.length > 1) return `C97 . ${this.getPatientPs(patient.ps)}`;
    },
    getPatientEstadio(estadio){
      switch (estadio) {
      case "Localizado":
        return "Loc";

      case "Extenso":
        return "Ext";

      case "Não se aplica":
        return "NA";

      default:
        return estadio;
      }
    },
    getPatientPs(ps){
      let selectedPs = this.performanceStatusList.filter(item => item._id == ps);

      return selectedPs.length > 0 ? selectedPs[0].name : "";
    },
    openCidModal(){
      this.cidModalActive = !this.cidModalActive;
    },
    selectCid(cid){
      this.search.byCid = cid._id;
      this.search.cidCode = cid.code;

      this.filterBy("byCid");
    },
    createParameters(){
      let params = "";

      if(this.search.activeNameOrRegisterNumber) params += `&queryParameter=${this.search.byNameOrRegisterNumber}`;
      if(this.search.activeCid) params += `&cidId=${this.search.byCid}`;
      if(this.search.activeEstadio) params += `&estadio=${this.search.byEstadio}`;

      return params;
    },
    filterBy(filterBy){
      switch (filterBy) {
      case "byNameOrRegisterNumber":
        this.search.activeNameOrRegisterNumber = true;
        this.search.activateClearSearchBtn = true;
        break;

      case "byCid":
        this.search.activeCid = true;
        break;

      case "byEstadio":
        this.search.activeEstadio = this.search.byEstadio ? true : false;
        break;
      }

      this.clearPagination();

      this.getPatientList(1, filterBy);
    },
    clearFilterBy(filterBy){
      switch (filterBy) {
      case "byNameOrRegisterNumber":
        this.clearSearchByNameFields();
        break;

      case "byCidEstadio":
        this.clearFilterByFields();
        break;
      }

      this.getPatientList(1, null, true);
    },
    clearSearchByNameFields(){
      this.search.byNameOrRegisterNumber = "";
      this.search.activeNameOrRegisterNumber = false;
      this.search.activateClearSearchBtn = false;

      this.clearPagination();
    },
    clearFilterByFields(){
      this.search.byCid = "";
      this.search.cidCode = "";
      this.search.byEstadio = "";
      this.search.activeCid = false;
      this.search.activeEstadio = false;

      this.clearPagination();
    },
    clearPagination(){
      this.pagination.current = 0;
      this.pagination.totalPages = 0;
      this.pagination.lastPage = false;
    },
    checkEndOfPage(){
      if (this.$refs.conversationList.scrollTop + this.$refs.conversationList.clientHeight + 250 >= this.$refs.conversationList.scrollHeight) {
        this.getPatientList(this.pagination.current + 1);
      }
    },
    controlSidebarHeight(){
      this.patientColumnHeightInterval = setInterval(($this) => {
        if(typeof $this.$refs.patientBox != "undefined" && $this.patientColumnHeight != $this.$refs.patientBox.offsetHeight){
          $this.patientColumnHeight = $this.$refs.patientBox.offsetHeight;
          $this.$refs.conversationList.style.height = `${$this.patientColumnHeight - 50}px`;
        }

        $this.checkEndOfPage();
      }, 500, this);
    },
    userHasPermission() {
      const decodedToken = decodeToken(this.$Session.get(this.$userSessionName).token.Value);
      const featurePrescriptions = 2;
      return decodedToken && decodedToken.FeaturesId.includes(featurePrescriptions);
    },
    startTicketSignalR(){

      try{
        if(this.ticketSignalR.connection != null) return true;

        this.ticketSignalR.connection = this.$SignalRConnector.startSignalR("messageprofessionalhub", (connection) => {
          this.startTicketSignalRListeners(connection);
        }, this.signalRTimeout, String(this.userId));

      }

      catch(erro) {
          console.log("Erro na comunicação com o SignalR: " + erro);
          if(this.ticketSignalR.retryCount < this.signalRMaxRetry){
            ++this.ticketSignalR.retryCount;

            this.startTicketSignalRListeners();
          }
      }
    },
    
    async startTicketSignalRListeners(connection){


      connection.on("PatientMessage", (res) => {
        this.loadingSignalR = true;
        this.newMessageForPatient = {
          patientId: res.fromId,
          ticketId: res.ticketId
        };
      });

      connection.on("ChatClosed", (res) => {
        this.loadingSignalR = true;
        this.closedTicketId = res.id;
        this.loadingSignalR = false;
      });

    },

    closeSignalRConnections(){
      if(this.ticketSignalR.timeout != null) clearTimeout(this.ticketSignalR.timeout);

      this.$SignalRConnector.stopSignalR(this.ticketSignalR.connection);

      this.ticketSignalR.active = false;
    }
  }
};
</script>

<style>
  @import '~@assets/css/components/patientTickets.css';
  @import '~@assets/css/views/patients.css';
  @import '~@assets/css/views/patientTickets.css';

.patient-list .columns {
  display: flex;
}

.patient-list .patient-sidebar {
  flex: 1;
  max-width: 400px;
}

.patient-list .patient-column {
  flex: 2;
  min-width: 300px;
}
</style>
