<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        :class="loadingContent ? 'loading large' : ''"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Novo Paciente
        </h1>

        <lila-message :alert-data="alert" />

        <div v-show="!loadingContent">
          <div class="columns">
            <div class="column is-5">
              <div class="field-group">
                <div class="field-group-header">
                  <p class="field-group-label-type">
                    CID/Estadio
                  </p>
                </div>

                <div class="field-group-body field-group-add-cid">
                  <lila-input
                    v-model="v.newCid.cid.name.$model"
                    placeholder="CID"
                    class="is-cursor-pointer input-cid"
                    :validation="v.newCid.cid.name"
                    :readonly="true"
                    @customClickEvent="openCidModal"
                  />

                  <lila-input
                    v-model="newCid.morphology"
                    placeholder="Morfologia"
                  />

                  <lila-select
                    v-model="v.newCid.estadio.$model"
                    placeholder="Estadio"
                    class="input-estadio"
                    :options="estadioList"
                    :validation="v.newCid.estadio"
                  />

                  <lila-button
                    type="button"
                    title="Adicionar"
                    @customClickEvent="addCid()"
                  />
                </div>

                <div
                  v-show="data.cidList.length > 0"
                  class="field-group-body"
                >
                  <table class="table is-fullwidth table-cid">
                    <thead>
                      <tr>
                        <th />
                        <th />
                        <th class="has-text-centered">
                          Excluir
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in data.cidList"
                        :key="item.cidId"
                      >
                        <td>CID <span>{{ item.cidName }}</span></td>
                        <td>Estadio <span>{{ item.estadio }}</span></td>
                        <td class="has-text-centered table-cid-delete">
                          <lila-svg-icon
                            :src="require(`@assets/icons/delete.svg`)"
                            class="is-cursor-pointer"
                            @customClickEvent="openDeleteModal(index)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="column is-2 middle-column">
              <div class="line" />
            </div>

            <div class="column is-5 middle-column">
              <lila-select
                v-model="data.ps"
                label="Performance Status"
                :options="performanceStatusList"
              />

              <lila-textarea
                v-model="data.allergy"
                label="Alergia Medicamentosa"
                placeholder="Digite aqui..."
              />

              <lila-textarea
                v-model="data.comorbidity"
                label="Comorbidades"
                placeholder="Digite aqui..."
              />

              <lila-textarea
                v-model="data.diagnostic"
                label="Diagnóstico Oncológico"
                placeholder="Digite aqui..."
              />
            </div>
          </div>

          <div class="registration-steps has-text-centered">
            <ul class="steps">
              <li />
              <li class="active" />
              <li />
              <li />
            </ul>
          </div>

          <lila-button
            type="submit"
            title="Salvar"
            class="submit-buttons"
            :second-button="{type: 'routeText', route: 'myPatients', title: 'Continuar mais tarde', class: 'dark-lilas'}"
            :loading="loading"
          />
        </div>
      </form>

      <lila-cid-modal
        :modal-active="cidModalActive"
        @customClickEvent="selectCid"
      />

      <div
        class="modal default-modal"
        :class="deleteModal.active ? 'is-active' : ''"
      >
        <div class="modal-background" />
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Excluir
            </p>
          </header>

          <section class="modal-card-body">
            <p>Deseja excluir esse registro?</p>
          </section>

          <footer class="modal-card-foot">
            <lila-button
              type="submit"
              title="Excluir"
              class="submit-buttons"
              :second-button="{type: 'button', title: 'Cancelar', class: 'dark-lilas'}"
              :loading="deleteLoading"
              @customClickEvent="deleteCid()"
              @customClickCancelEvent="closeDeleteModal()"
            />
          </footer>
        </div>
      </div>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Textarea from "@components/shared/fields/Textarea.vue";
import Button from "@components/shared/button/Button.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";
import CidModal from "@components/shared/lila/CidModal.vue";

import { required } from "@vuelidate/validators";

export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-textarea": Textarea,
    "lila-button": Button,
    "lila-svg-icon": SvgIcon,
    "lila-message": Message,
    "lila-cid-modal": CidModal,
    "lila-default-template": DefaultTemplate
  },
  data() {
    return {
      data: {
        id: this.$route.params.id,
        accesscode: "",
        allergy: "",
        attendanceplaceid: 0,
        birthdate: "",
        cep: "",
        cids: [],
        cpf: "",
        city: "",
        comorbidity: "",
        complement: "",
        diagnostic: "",
        email: "",
        gender: "",
        name: "",
        neighborhood: "",
        number: "",
        ps: "",
        phone: "",
        photo: "",
        relativename: "",
        relativephone: "",
        street: "",
        uf: "",
        cidList: [],
        teamId:0,
        registernumber: ""
      },
      newCid: {
        addNew: false,
        cid: {
          _id: "",
          name: ""
        },
        morphology: "",
        estadio: ""
      },
      estadioList: [
        {_id: "I", name: "I"},
        {_id: "II", name: "II"},
        {_id: "III", name: "III"},
        {_id: "IV", name: "IV"},
        {_id: "Localizado", name: "Localizado"},
        {_id: "Extenso", name: "Extenso"},
        {_id: "Não se aplica", name: "Não se aplica"}
      ],
      performanceStatusList: [
        {_id: 0, name: "PS 0"},
        {_id: 1, name: "PS I"},
        {_id: 2, name: "PS II"},
        {_id: 3, name: "PS III"},
        {_id: 4, name: "PS IV"}
      ],
      cidModalActive: false,
      deleteModal: {
        cidIndex: "",
        active: false
      },
      deleteLoading: false,
      loading: false,
      loadingContent: false,
      redirect: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    if(this.newCid.addNew){
      return {
        newCid: {
          cid: {
            name: {required}
          },
          estadio: {required}
        }
      };
    }else{
      return {
        newCid: {
          cid: {
            name: {}
          },
          estadio: {}
        }
      };
    }
  },
  created(){
    if (this.$route.query.customMessageType) {
      const data = {customMessage: {type:this.$route.query.customMessageType, message: this.$route.query.customMessage}};
      this.$Utilities.showResultMessage(data, this.alert);
    }
    this.checkRegistration();
  },
  methods: {
    submitForm(){
      this.alert.show = false;
      this.newCid.addNew = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      let requestList = [];

      this.data.ps = parseInt(this.data.ps);

      if(this.data.cidList.length > 0) requestList.push(this.saveCid());
      if(this.data.ps
                   || this.data.comorbidity
                   || this.data.diagnostic
                   || this.data.allergy) requestList.push(this.saveClinicalData());

      if(requestList.length == 0){
        this.alert.message = "É necessario informar alguma das informações complementares.";
        this.alert.type = "warning";
        this.alert.show = true;

        this.loading = false;

        return;
      }

      Promise
        .all(requestList)
        .then(() => this.$router.push({
          name: "step3PatientInformation",
          params: {
            id: this.data.id
          },
          query: {
            message: "complementaryInformation"
          }
        }))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          this.$router.push({name: "myPatients", query: {
              customMessageType: "danger",
              customMessage: "Não foi possivel salvar as informações complementares do paciente."
            }
          });
        })
        .finally(() => this.loading = false);
    },
    saveCid(){
      return this.$axios({
        method: "POST",
        url: `api/cid/patient/${this.data.id}`,
        data: this.data.cidList,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      });
    },
    saveClinicalData(){
      return this.$axios({
        method: "PUT",
        url: `api/patient/${this.data.id}`,
        data: this.data,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      });
    },
    checkRegistration(){
      this.loadingContent = true;

      Promise
        .all([
          this.verifyPatientStatus(),
          this.getPatient(),
          this.getPatientCidList()
        ])
        .then(() => {
          if(this.redirect) this.$router.push({name: "myPatients"});
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$router.push({name: "myPatients"});
        })
        .finally(() => this.loadingContent = false);
    },
    verifyPatientStatus(){
      this.$axios
        .get(
          `api/patient/record/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.Status != "Pending") this.redirect = true;
        });
    },
    getPatient(){
      return this.$axios
        .get(
          `api/patient/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if((res.data.PS != null && res.data.PS != 0)
                                   || res.data.Comorbidity != null
                                   || res.data.Diagnostic != null
                                   || res.data.Allergy != null) this.redirect = true;

          for(let index in res.data){
            if(index.toLowerCase() == "attendanceplace"){
              this.data.attendanceplaceid = res.data[index].Id;
            }else{
              this.data[index.toLowerCase()] = res.data[index];
            }
          }
          this.data["teamId"] = res.data.Doctor.Id;
        });
    },
    getPatientCidList(){
      return this.$axios
        .get(
          `api/cid/patient/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          if(res.data.length > 0) this.redirect = true;
        });
    },
    selectCid(cid){
      this.newCid.cid._id = cid._id;
      this.newCid.cid.name = cid.code;
    },
    addCid(){
      this.newCid.addNew = true;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.data.cidList.push({
        cidId: this.newCid.cid._id,
        cidName: this.newCid.cid.name,
        morphology: this.newCid.morphology,
        estadio: this.newCid.estadio,
        isEnable: true
      });

      this.newCid.cid._id = "";
      this.newCid.cid.name = "";
      this.newCid.morphology = "";
      this.newCid.estadio = "";

      this.v.$reset();
    },
    deleteCid(){
      this.data.cidList.splice(this.deleteModal.cidIndex, 1);

      this.closeDeleteModal();
    },
    openCidModal(){
      this.cidModalActive = !this.cidModalActive;
    },
    openDeleteModal(cidIndex){
      this.deleteModal.active = true;
      this.deleteModal.cidIndex = cidIndex;
    },
    closeDeleteModal(){
      this.deleteModal.active = false;
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';
</style>
