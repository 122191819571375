<template>
  <div class="cid-modal cid-modal-component">
    <div
      class="modal"
      :class="activeToggle == modalActive ? 'is-active' : ''"
    >
      <div class="modal-background" />

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            CID
          </p>
        </header>

        <section class="modal-card-body ">
          <div class="search">
            <lila-input
              v-model="search"
              placeholder="Pesquisar"
            />
          </div>

          <div
            class="cid-list"
            :class="loadingContent ? 'loading large' : ''"
          >
            <lila-message :alert-data="alert" />

            <ul>
              <li
                v-for="cid in filteredList"
                :key="cid._id"
                @click="clickEvent(cid)"
              >
                <span>{{ cid.code }}</span> {{ cid.description }}
              </li>
            </ul>
          </div>
        </section>

        <footer class="modal-card-foot">
          <button
            class="button is-rounded default"
            @click="closeModal"
          >
            Fechar
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@components/shared/fields/Input.vue";
import Message from "@components/shared/message/Message.vue";

export default {
  components: {
    "lila-input": Input,
    "lila-message": Message
  },
  props: {
    modalActive: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cidList: [],
      search: "",
      activeToggle: true,
      loadingContent: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  computed: {
    filteredList() {
      return this.cidList.filter(item => {
        if(this.search != "") return item.code.toLowerCase().indexOf(this.search.toLowerCase()) != -1
                                                 || item.description.toLowerCase().indexOf(this.search.toLowerCase()) != -1;

        return true;
      });
    }
  },
  created(){
    this.getCidList();
  },
  methods: {
    getCidList(){
      this.loadingContent = true;

      this.$axios
        .get(
          "api/cidtype",
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.cidList = res.data.map(item => {
            return {
              _id: item.Id,
              code: item.CIDCode,
              description: item.Description
            };
          });
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    clickEvent(cid){
      this.$emit("customClickEvent", cid);

      this.closeModal();
    },
    closeModal(){
      this.activeToggle = !this.activeToggle;
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/cidModal.css';
</style>
