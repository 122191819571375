<template>
  <div class="patient-card patient-card-component">
    <div 
      v-for="patient in dataList" 
      :key="patient.id" 
      class="card" 
      :class="selectedPatient && (selectedPatient.id == patient.id) ? 'active' : ''"
      @click="clickEvent(patient)"
    >
      <router-link :to="{name: routerPath, params: {id: patient.id}}">
        <div class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <div class="is-profile-picture-64x64">
                  <img
                    :src="getProfilePicture(patient.profilePicture)"
                    alt="Foto do paciente"
                  >
                </div>
              </figure>
            </div>

            <div class="media-content">
              <div class="content">
                <div class="content-line">
                  <div class="patient">
                    {{ patient.name }}
                  </div>

                  <div class="cid">
                    {{ patient.cid }}
                  </div>
                </div>
                                
                <div class="detail">
                  {{ $Utilities.getAbbrevGender(patient.gender) }}{{ patient.birthDate ? `, ${$Utilities.getAge(patient.birthDate)}` : '' }}
                </div>

                <div
                  v-if="patient.diagnostic"
                  class="diagnostic"
                >
                  {{ messageSlice(patient.diagnostic) }}
                </div>

                <div
                  v-if="patient.painLevel"
                  class="painLevel"
                >
                  Dor: <strong>{{ patient.painLevel }}</strong>
                </div>                            

                <div class="serviceLocation">
                  <strong>{{ patient.doctor }}</strong>
                </div>
              </div>
            </div>
          </article>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      required: true,
      type: Array,
      default: () => {
        return [{
          id: "",
          name: "",
          profilePicture: "",
          cid: "",
          gender: "",
          birthDate: "",
          diagnostic: "",
          painLevel: "",
          serviceLocation: "",
          doctor: ""
        }];
      }
    },
    selectedPatient: {
      required: false,
      type: Object,
      default: null
    },
    routerPath: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    clickEvent(patient){
      this.$emit("customClickEvent", patient);
    },
    getProfilePicture(profilePicture) {
      return (profilePicture != "" && profilePicture != null) ? profilePicture : require(`@components/shared/lila/profilePicturePlaceholder/${(Math.floor(Math.random() * 5) + 1)}.png`);
    },
    messageSlice(message){
      if(message?.length > 75) return `${message.substring(0, 75)}...`;

      return message;
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/patientCard.css';
</style>