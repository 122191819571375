<template>
  <div>
    <form
      class="container basic-info-form medical-card-form"
      :class="loadingContent ? 'loading large' : ''"
      @submit.prevent="submitForm()"
    >
      <lila-message :alert-data="alert" />

      <div v-show="!loadingContent">
        <div
          v-if="!$AccessControl.cannot($Session.get($userSessionName).profile, 'export_patient_data')"
          class="box-export-medical-record"
        >
          <lila-export-button
            :api-url="`/api/patient/export/${data.id}`"
            :alert="alert"
          />
        </div>

        <div class="field-group">
          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Estado de acesso ao sistema
            </p>
          </div>

          <div class="field-group-body">
            <div class="field-body columns">
              <lila-input
                v-if="data.status === 'Pending'"
                class="column is-narrow is-6"
                model-value="Não fez primeiro acesso"
                :disabled="true"
              />

              <lila-select
                v-else
                v-model="data.status"
                class="column is-narrow is-6"
                :options="statusList"
                :readonly="!checkInstalledApp || viewOnly"
              />
            </div>
          </div>

          <div class="division-line" />

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Estado de vida
            </p>
          </div>

          <div class="field-group-body">
            <div class="field-body columns">
              <div class="column is-narrow is-6">
                <lila-select
                  v-model="v.data.lifestatus.$model"
                  :options="lifeStatusList"
                  :validation="v.data.lifestatus"
                  @customChangeEvent="clearLifeStatus"
                />
              </div>
            </div>

            <div
              v-show="data.lifestatus == 'Deceased'"
              class="field-body columns is-multiline"
            >
              <div class="column is-narrow is-6">
                <lila-datepicker
                  v-model="v.data.deathdate.$model"
                  :disable-future-dates="$moment().format('MM/DD/YYYY')"
                  placeholder="Data de falecimento"
                  :validation="v.data.deathdate"
                />
              </div>
            </div>
          </div>

          <lila-textarea
            v-show="data.lifestatus == 'Deceased'"
            v-model="v.data.deathnotes.$model"
            placeholder="Notas"
            :validation="v.data.deathnotes"
          />

          <div class="division-line" />

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Paciente
            </p>
          </div>

          <div class="field-group-body">
            <lila-input
              v-model="v.data.name.$model"
              placeholder="Nome"
              :validation="v.data.name"
            />

            <lila-input
              v-model="v.data.registernumber.$model"
              placeholder="Número de Matrícula"
              :validation="v.data.registernumber"
            />
            
            <lila-select
              v-model="v.data.gender.$model"
              placeholder="Sexo"
              :options="genderList"
              :validation="v.data.gender"
            />


            <lila-input
              v-model="v.data.email.$model"
              placeholder="E-mail"
              :validation="v.data.email"
            />
          </div>

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              CID/Estadio
            </p>
          </div>

          <div
            v-if="!viewOnly"
            class="field-group-body field-group-add-cid"
          >
            <lila-input
              v-model="v.newCid.cid.name.$model"
              placeholder="CID"
              class="is-cursor-pointer input-cid"
              :validation="v.newCid.cid.name"
              :readonly="true"
              @customClickEvent="openCidModal"
            />

            <lila-input
              v-model="v.newCid.morphology.$model"
              placeholder="Morfologia"
              class="input-morphology"
              :validation="v.newCid.morphology"
            />

            <lila-select
              v-model="v.newCid.estadio.$model"
              placeholder="Estadio"
              class="input-estadio"
              :options="estadioList"
              :validation="v.newCid.estadio"
            />

            <lila-button
              type="button"
              title="Adicionar"
              @customClickEvent="addCid()"
            />
          </div>

          <div
            v-show="data.cidList.length > 0"
            class="field-group-body"
          >
            <table class="table is-fullwidth table-cid">
              <thead>
                <tr>
                  <th />
                  <th />
                  <th
                    v-if="!viewOnly"
                    class="has-text-centered"
                  >
                    Excluir
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in data.cidList"
                  :key="index"
                >
                  <td>CID <span>{{ item.cidName }}</span></td>
                  <td>Estadio <span>{{ item.estadio }}</span></td>
                  <td
                    v-if="!viewOnly"
                    class="has-text-centered table-cid-delete"
                  >
                    <lila-svg-icon
                      :src="require(`@assets/icons/delete.svg`)"
                      class="is-cursor-pointer"
                      @customClickEvent="openDeleteModal(item.id, index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="division-line" />

          <div class="field-group-body">
            <lila-select
              v-model="data.ps"
              :label="`Performance Status${data.hasweeklyreport ? ' informado pelo paciente' : ''}`"
              :options="performanceStatusList"
              :readonly="true"
            />
          </div>

          <div class="division-line" />

          <div class="field-group-body">
            <lila-textarea
              v-model="data.allergy"
              label="Alergia Medicamentosa"
              placeholder="Digite aqui..."
              :readonly="viewOnly"
            />

            <lila-textarea
              v-model="data.comorbidity"
              label="Comorbidades"
              placeholder="Digite aqui..."
              :readonly="viewOnly"
            />

            <lila-textarea
              v-model="data.diagnostic"
              label="Diagnóstico Oncológico"
              placeholder="Digite aqui..."
              :readonly="viewOnly"
            />
          </div>

          <div class="division-line" />

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Dados do Paciente
            </p>
          </div>

          <div class="field-group-body">
            <lila-mask
              v-model="v.data.cpf.$model"
              placeholder="CPF"
              mask="###.###.###-##"
              :validation="v.data.cpf"
              :readonly="viewOnly"
            />

            <lila-mask
              v-model="v.data.phone.$model"
              placeholder="Telefone"
              :mask="['(##) ####-####', '(##) #####-####']"
              :validation="v.data.phone"
              :readonly="viewOnly"
            />

            <lila-datepicker
              v-model="v.data.birthdate.$model"
              placeholder="Data de Nascimento"
              :validation="v.data.birthdate"
              :readonly="viewOnly"
            />
          </div>

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Contato Parente, Amigo(a)...?
            </p>
          </div>

          <div class="field-group-body">
            <lila-input
              v-model="data.relativename"
              placeholder="Nome do Parente"
              :readonly="viewOnly"
            />

            <lila-mask
              v-model="data.relativephone"
              placeholder="Telefone"
              :mask="['(##) ####-####', '(##) #####-####']"
              :readonly="viewOnly"
            />
          </div>

          <div class="division-line" />

          <div class="field-group-header align-title">
            <p class="field-group-label-type">
              Endereço
            </p>
          </div>

          <div class="field-group-body">
            <div class="field-group-body">
              <lila-mask
                v-model="data.cep"
                placeholder="CEP"
                mask="#####-###"
                :loading="loadingCep"
                :readonly="viewOnly"
                @keyup="changeCep()"
              />

              <lila-input
                v-model="data.street"
                placeholder="Endereço"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.number"
                placeholder="Número"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.complement"
                placeholder="Complemento"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.neighborhood"
                placeholder="Bairro"
                :readonly="viewOnly"
              />

              <lila-input
                v-model="data.city"
                placeholder="Cidade"
                :readonly="viewOnly"
              />

              <lila-select
                v-model="data.uf"
                placeholder="Estado"
                :options="statesList"
                :readonly="viewOnly"
              />
            </div>
          </div>
        </div>

        <lila-button
          v-if="!viewOnly"
          type="submit"
          title="Salvar"
          class="submit-buttons"
          :loading="loading"
        />
      </div>
    </form>

    <lila-cid-modal
      :modal-active="cidModalActive"
      @customClickEvent="selectCid"
    />

    <div
      class="modal default-modal"
      :class="deleteModal.active ? 'is-active' : ''"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Excluir
          </p>
        </header>

        <section class="modal-card-body">
          <p>Deseja excluir esse registro?</p>
        </section>

        <footer class="modal-card-foot">
          <lila-button
            type="submit"
            title="Excluir"
            class="submit-buttons"
            :second-button="{type: 'button', title: 'Cancelar', class: 'dark-lilas'}"
            :loading="deleteLoading"
            @customClickEvent="controllDeleteCid()"
            @customClickCancelEvent="closeDeleteModal()"
          />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@components/shared/fields/Select.vue";
import Textarea from "@components/shared/fields/Textarea.vue";
import Button from "@components/shared/button/Button.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import CidModal from "@components/shared/lila/CidModal.vue";
import ExportButton from "@components/shared/lila/ExportButton.vue";
import Message from "@components/shared/message/Message.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";
import LilaInput from "@components/shared/fields/Input.vue";
import LilaDatepicker from "@components/shared/fields/LilaDatepicker.vue";

import GenderOptions from "@assets/options/gender.json";
import StatesOptions from "@assets/options/states.json";

import { required, requiredIf, email } from "@vuelidate/validators";

const validCpf = function(value){
  if(value) return this.$Utilities.validateCPF(value);

  return true;
};

const dateLessOrEqualToCurrent = function(value){
  if(value) return this.$moment(value, "DD/MM/YYYY").unix() <= this.$moment.utc().unix();

  return true;
};

export default {
  components: {
    LilaDatepicker,
    "lila-select": Select,
    "lila-textarea": Textarea,
    "lila-button": Button,
    "lila-svg-icon": SvgIcon,
    "lila-cid-modal": CidModal,
    "lila-export-button": ExportButton,
    "lila-message": Message,
    LilaMask,
    LilaInput
  },
  data(){
    return {
      viewOnly: false,
      data: {
        id: this.$route.params.id,
        name: "",
        email: "",
        attendanceplaceid: 0,
        ps: "",
        hasweeklyreport: false,
        birthdate: "",
        gender: "",
        cpf: "",
        phone: "",
        cep: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        uf: "",
        comorbidity: "",
        diagnostic: "",
        allergy: "",
        relativename: "",
        relativephone: "",
        status: "",
        lifestatus: "",
        deathdate: "",
        deathnotes: "",
        registernumber: "",
        cidList: []
      },
      newCid: {
        addNew: false,
        cid: {
          _id: "",
          name: ""
        },
        morphology: "",
        estadio: ""
      },
      genderList: GenderOptions.options,
      statusList: [
        {_id: "Active", name: "Login habilitado"},
        {_id: "Inactive", name: "Login desabilitado"}
      ],
      lifeStatusList: [
        {_id: "InTreatment", name: "Em tratamento"},
        {_id: "OutTreatment", name: "Fora de tratamento"},
        {_id: "Deceased", name: "Falecido"}
      ],
      estadioList: [
        {_id: "I", name: "I"},
        {_id: "II", name: "II"},
        {_id: "III", name: "III"},
        {_id: "IV", name: "IV"},
        {_id: "Localizado", name: "Localizado"},
        {_id: "Extenso", name: "Extenso"},
        {_id: "Não se aplica", name: "Não se aplica"}
      ],
      performanceStatusList: [
        {_id: 0, name: "PS 0"},
        {_id: 1, name: "PS I"},
        {_id: 2, name: "PS II"},
        {_id: 3, name: "PS III"},
        {_id: 4, name: "PS IV"}
      ],
      medicalCareLocationList: this.$Session.get(this.$userSessionName).doctor.Addresses[0] ? this.$Session.get(this.$userSessionName).doctor.Addresses.map(item => {
        return {
          _id: item.Id,
          name: item.Street
        };
      }) : [],
      statesList: StatesOptions.options,
      cidModalActive: false,
      deleteModal: {
        id: null,
        cidIndex: null,
        active: false
      },
      deleteLoading: false,
      sendingCode: false,
      loading: false,
      loadingCep: false,
      loadingContent: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    if(this.newCid.addNew){
      return {
        data: {
          name: {},
          gender: {},
          email: {},
          attendanceplaceid: {},
          cpf: {},
          phone: {},
          birthdate: {},
          lifestatus: {required},
          deathdate: {
            required: requiredIf(() => this.data.lifestatus == "Deceased")
          },
          deathnotes: {
            required: requiredIf(() => this.data.lifestatus == "Deceased")
          },
          registernumber: {}
        },
        newCid: {
          cid: {
            name: {
              required: requiredIf(() => this.data.cidList.length === 0)
            }
          },
          morphology: {},
          estadio: {
            required: requiredIf(() => this.data.cidList.length === 0)
          }
        }
      };
    }else{
      return {
        data: {
          name: {required},
          gender: {required},
          email: {email},
          attendanceplaceid: {required},
          cpf: {
            required,
            validCpf
          },
          phone: {
            required
          },
          birthdate: {dateLessOrEqualToCurrent},
          lifestatus: {required},
          deathdate: {
            required: requiredIf(() => this.data.lifestatus == "Deceased")
          },
          deathnotes: {
            required: requiredIf(() => this.data.lifestatus == "Deceased")
          },
          registernumber: {}
        },
        newCid: {
          cid: {
            name: {
              required: requiredIf(() => this.data.cidList.length === 0)
            }
          },
            morphology: {},
          estadio: {
            required: requiredIf(() => this.data.cidList.length === 0)
          }
        }
      };
    }
  },
  computed: {
    checkInstalledApp(){
      return this.data.status != "Pending";
    }
  },
  watch:{
    $route (to){
      this.data.id = to.params.id;
      this.data.ps = "";
      this.data.hasweeklyreport = false;
      this.getPatient();
    }
  },
  created(){
    this.viewOnly = this.$AccessControl.cannot(this.$Session.get(this.$userSessionName).profile, "edit_medical_record");
    this.getPatient();
  },
  methods: {
    submitForm(){
      this.newCid.addNew = false;
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid){
        this.$Utilities.scrollToInvalidField();
        return;
      }

      if(this.data.cidList.length == 0){
        this.$Utilities.controlAlert(null, this.alert, "warning", "Um CID deve ser informado.");
        return;
      }

      this.loading = true;

      let requestList = [];
      let newCidList = this.data.cidList.filter(item => !item.inserted);
      let editCidList = this.data.cidList.filter(item => item.inserted);

      requestList.push(this.savePatient());

      if(newCidList.length > 0) requestList.push(this.saveCid(newCidList));
      if(editCidList.length > 0) requestList.push(this.saveCid(editCidList, false));

      Promise
        .all(requestList)
        .then(() => {
          this.updatePatientCard();
          this.updatePatientSummaryCard();
          this.updatePatientTicketCard();
          this.updatePatientSymptomAlertCard();

          this.$Utilities.controlAlert(null, this.alert, "edit");
        })
        .catch(error => {
          console.error(error);
          if (error && error.response) {
            this.$Utilities.verifyToken(error.response.status);
            if (error.response.data) {
              this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
            } else {
              this.$Utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert, "danger");
            }
          } else {
            this.$Utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert, "danger");
          }
        })
        .finally(() => this.loading = false);
    },
    savePatient(){
      let patient = Object.assign({}, this.data);

      if(patient.birthdate) patient.birthdate = this.$Utilities.formatDate(patient.birthdate, "DD/MM/YYYY", "MM/DD/YYYY");
      if(patient.deathdate) patient.deathdate = this.$Utilities.formatDate(patient.deathdate, "DD/MM/YYYY", "MM/DD/YYYY");

      return this.$axios({
        method: "PUT",
        url: `api/patient/${patient.id}`,
        data: patient,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      });
    },
    saveCid(cidList, isInsert = true){
      return this.$axios({
        method: isInsert ? "POST" : "PUT",
        url: `api/cid/patient/${this.data.id}`,
        data: cidList,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      }).then((res) => {
        if(isInsert){
          this.data.cidList = this.data.cidList.filter((item) => item.inserted == true);

          res.data.forEach(item => {
            this.data.cidList.push({
              id: item.Id,
              cidId: item.CID.Id,
              cidName: item.CID.CIDCode,
              morphology: item.Morphology,
              estadio: item.Estadio,
              isEnable: item.IsEnable,
              inserted: true,
              deleted: false
            });
          });
        }
      });
    },
    deleteCid(cidId, patientId, cidIndex){
      this.deleteLoading = true;

      this.$axios({
        method: "DELETE",
        url: `/api/cid/${cidId}/patient/${patientId}`,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          this.data.cidList.splice(cidIndex, 1);
        })
        .catch(error => {
          console.error(error);
          if (error && error.response) {
            this.$Utilities.verifyToken(error.response.status);
            if (error.response.data) {
              this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
            } else {
              this.$Utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert, "danger");
            }
          } else {
            this.$Utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert, "danger");
          }
        })
        .finally(() => {
          this.deleteLoading = false;

          this.closeDeleteModal();
        });
    },
    getPatient(){
      this.loadingContent = true;

      this.$axios
        .get(
          `api/patient/record/${this.data.id}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          for(let index in res.data){
            if(index.toLowerCase() == "attendanceplace"){
              this.data.attendanceplaceid = res.data[index].Id;
            }else if(index.toLowerCase() == "ps" && res.data[index] == null){
              this.data.ps = "";
            }else if(index.toLowerCase() == "ps" && res.data["CurrentPS"] != null){
              this.data.ps = res.data["CurrentPS"];
              this.data.hasweeklyreport = true;
            }else if(index == "BirthDate") {
              if(res.data.BirthDate){
                this.data[index.toLowerCase()] = this.$Utilities.formatDate(res.data.BirthDate, "MM/DD/YYYY", "DD/MM/YYYY");
              }
            }else if(index == "DeathDate" && res.data.DeathDate != null) {
              if(res.data.LifeStatus == "Deceased") {
                this.data[index.toLowerCase()] = this.$Utilities.formatDate(res.data.DeathDate, "YYYY-MM-DD", "DD/MM/YYYY");
              }
            }else if(index == "LifeStatus") {
              if(res.data[index] != "None") this.data[index.toLowerCase()] = res.data[index];
            }else if(index == "CIDs"){
              this.data.cidList = res.data.CIDs.map(item => {
                return {
                  id: item.Id,
                  cidId: item.CID.Id,
                  cidName: item.CID.CIDCode,
                  morphology: item.Morphology,
                  estadio: item.Estadio,
                  isEnable: item.IsEnable,
                  inserted: true,
                  deleted: false
                };
              });
            }else if(typeof this.data[index.toLowerCase()] != "undefined"){
              this.data[index.toLowerCase()] = res.data[index];
            }
          }
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    clearLifeStatus(){
      this.data.deathdate = null;
      this.data.deathnotes = null;
    },
    selectCid(cid){
      this.newCid.cid._id = cid._id;
      this.newCid.cid.name = cid.code;
    },
    addCid(){
      this.newCid.addNew = true;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.data.cidList.push({
        id: null,
        cidId: this.newCid.cid._id,
        cidName: this.newCid.cid.name,
        morphology: this.newCid.morphology,
        estadio: this.newCid.estadio,
        isEnable: true,
        inserted: false,
        deleted: false
      });

      this.newCid.cid._id = "";
      this.newCid.cid.name = "";
      this.newCid.morphology = "";
      this.newCid.estadio = "";

      this.v.$reset();
    },
    updatePatientCard(){
      this.$emit("updatePatient", this.data);
    },
    updatePatientSummaryCard(){
      this.$emit("updatePatientCardSummary", this.data);
    },
    updatePatientTicketCard(){
      this.$emit("updatePatientTicketCard", this.data);
    },
    updatePatientSymptomAlertCard(){
      this.$emit("updatePatientSymptomAlertCard", this.data);
    },
    controllDeleteCid(){
      if(this.deleteModal.id){
        this.deleteCid(this.deleteModal.id, this.data.id, this.deleteModal.cidIndex);
      }else if(this.deleteModal.cidIndex || this.deleteModal.cidIndex == 0){
        this.data.cidList.splice(this.deleteModal.cidIndex, 1);

        this.closeDeleteModal();
      }
    },
    openCidModal(){
      this.cidModalActive = !this.cidModalActive;
    },
    openDeleteModal(id, cidIndex){
      this.deleteModal.id = id;
      this.deleteModal.cidIndex = cidIndex;
      this.deleteModal.active = true;
    },
    closeDeleteModal(){
      this.deleteModal.id = null;
      this.deleteModal.cidIndex = null;
      this.deleteModal.active = false;
    },
    changeCep(){
      this.$Utilities.buscarEndereco(this.data, this);
    },
    sendCode(){
      this.alert.show = false;
      this.sendingCode = true;

      this.$axios({
        method: "POST",
        url: `api/patient/token/${this.data.id}`,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => this.$Utilities.controlAlert(null, this.alert, "success", "As instruções para o uso do aplicativo foi encaminhada para o e-mail do paciente."))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);

          if(error.response.status == 400){
            this.appCodeModal.invalidPhone = true;
            this.$Utilities.controlAlert(null, this.alert, "warning", "O e-mail com as instruções para o uso do aplicativo foi enviado, porem não foi possivel enviar o SMS. Verifique se o telefone do paciente esta correto.");
          }else{
            this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
          }
        })
        .finally(() => this.sendingCode = false);
    }
  }
};
</script>

<style scoped>
    @import '~@assets/css/views/medicalRecord.css';
</style>
