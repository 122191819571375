<template>
  <lila-login-template>
    <template #mainContent>
      <form
        class="login-form full-width"
        @submit.prevent="submitForm"
      >
        <h1 class="title">
          Bem Vindo!
        </h1>

        <lila-message :alert-data="alert" />

        <lila-input
          v-model="v.data.login.$model"
          placeholder="Login"
          :validation="v.data.login"
        />

        <lila-input
          v-model="v.data.password.$model"
          placeholder="Senha"
          type="password"
          :validation="v.data.password"
        />

        <lila-button
          type="routeText"
          path="forgotPassword"
          class="forgot-password"
          button-icon="lock.svg"
          title="Esqueci a senha"
        />

        <!--lila-checkbox
                    title="Me mantenha conectado"
                    class="keep-connected"
                    v-model="data.manterConectado" /-->

        <lila-button
          type="submit"
          title="Entrar"
          class="submit-buttons"
          :is-centralized="true"
          :second-button="{type: 'externalLink', route: 'https://bit.ly/lila-cadastro-profissional', title: 'Quero me Cadastrar'}"
          :loading="loading"
        />
      </form>
    </template>
  </lila-login-template>
</template>

<script>
// import { useVuelidate } from '@vuelidate/core'

import LoginTemplate from "@components/template/LoginTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import { required, email, maxLength } from "@vuelidate/validators";

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-login-template": LoginTemplate
  },

  // setup () {
  //   return { v: useVuelidate() }
  // },
  data() {
    return {
      data: {
        login: "",
        password: "",
        manterConectado: false
      },
      alert: {
        message: "",
        type: "",
        show: false
      },
      loading: false
    };
  },
  validations() {
    return {
      data: {
        login: {
          required,
          email,
          maxLength: maxLength(255)
        },
        password: {
          required,
          maxLength: maxLength(255)
        }
      }
    };
  },
  created() {
    let currentUrl = new URLSearchParams(window.location.search);

    if(this.$Utilities.hasProperty(this.$route.params, "message")){
      this.$Utilities.controlAlert(null, this.alert, this.$route.params.message);
    }

    if(currentUrl.get("passwordCreated") != null){
      this.$Utilities.controlAlert(null, this.alert, "firstPasswordCreated");
    }
  },
  methods: {
    submitForm() {
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      this.$axios
        .post("api/account/login", this.data)
        .then(res => {
          let profile = "";

          if(typeof res.data.Professional != "undefined"){
            profile = `professional_${res.data.Professional.Profile.toLowerCase()}`;
            res.data.Professional.Doctor.BirthDate = res.data.Professional.Doctor.BirthDate ? this.$Utilities.formatDate(res.data.Professional.Doctor.BirthDate, "MM/DD/YYYY", "DD/MM/YYYY") : "";
          }else{
            profile = "doctor";
            res.data.Doctor.BirthDate = res.data.Doctor.BirthDate ? this.$Utilities.formatDate(res.data.Doctor.BirthDate, "MM/DD/YYYY", "DD/MM/YYYY") : "";
          }

        
          this.$Session.set(this.$userSessionName, {
            token: res.data.Token,
            doctor: typeof res.data.Professional != "undefined" ? res.data.Professional.Doctor : res.data.Doctor,
            professional: typeof res.data.Professional != "undefined" ? res.data.Professional : null,
            profile: profile,
            isTrial: res.data.Professional.IsTrial,
            trial: res.data.Professional.TrialRemainingDays
          });  

          
        this.$router.push({name: "completeYourRegistration"});
        })
        .catch(error => {
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    }
  }
};
</script>

<style></style>
