<template>
  <div class="history-record-card history-record-card-component">
    <div class="columns is-multiline">
      <div 
        v-for="(historyRecord, index) in dataList"
        :key="`${historyRecord._id}-${Math.random()}`"
        class="column"
        :class="cardSize"
      >
        <div class="card">
          <p class="type">
            {{ historyRecord.historyRecordTypeName }}
          </p>
          <p class="date">
            {{ historyRecord.date }}
          </p>
          <p class="description">
            {{ descriptionSlice(historyRecord.historyRecordNotes) }}
          </p>

          <div class="box-buttons">
            <div class="buttons">
              <button 
                type="button" 
                class="button is-rounded default"
                @click="clickEditEvent(historyRecord, index)"
              >
                Editar
              </button>
              <button 
                type="button" 
                class="button is-rounded cancel"
                :class=" (typeof historyRecord.deleteLoading != 'undefined' && historyRecord.deleteLoading) ? 'is-loading' : ''"
                @click="clickDeleteEvent(historyRecord, index)"
              >
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      required: true,
      type: Array,
      default: () => {
        return [{
          _id: "",
          historyRecordTypeId: "",
          historyRecordTypeName: "",
          date: "",
          historyRecordNotes: ""
        }];
      }
    },
    extendedDescription: {
      required: false,
      type: Boolean,
      default: false
    },
    cardSize: {
      required: false,
      type: String,
      default: "is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-4-fullhd"
    }
  },
  methods: {
    clickEditEvent(historyRecord, index){
      this.$emit("customClickEditEvent", historyRecord, index);
    },
    clickDeleteEvent(historyRecord, index){
      this.$emit("customClickDeleteEvent", historyRecord, index);
    },
    descriptionSlice(historyRecordNotes){
      if(this.extendedDescription){
        if(historyRecordNotes != null && historyRecordNotes.length > 350) return `${historyRecordNotes.substring(0, 350)}...`;
      }else{
        if(historyRecordNotes != null && historyRecordNotes.length > 145) return `${historyRecordNotes.substring(0, 120)}...`;
      }

      return historyRecordNotes;
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/historyRecordCard.css';
</style>