<template>
  <div class="diagnostic-card diagnostic-card-component">
    <div 
      v-for="patient in dataList" 
      :key="patient.alertId" 
      class="card diagnostic-severe" 
      @click="clickEvent(patient)"
    >
      <router-link :to="{name: routerPath, params: {id: patient.id}}">
        <div class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <div class="is-profile-picture-64x64">
                  <img
                    class="is-rounded"
                    :src="getProfilePicture(patient.profilePicture)"
                    alt="Foto do paciente"
                  >
                </div>
              </figure>
            </div>

            <div class="media-content">
              <div class="content">
                <div class="content-line">
                  <div class="patient">
                    {{ patient.name }}
                  </div>

                  <div class="cid">
                    {{ patient.cid }}
                  </div>
                </div>

                <div class="content-line">
                  <div class="diagnostic">
                    {{ diagnosticType(patient.diagnostic) }}
                  </div>

                  <div class="date">
                    <span>{{ patient.date }}</span> 
                                        
                    <div
                      v-show="patient.deletingAlert"
                      class="loading very-small"
                    />

                    <lila-svg-icon 
                      v-show="!patient.deletingAlert"
                      :src="require(`@assets/icons/delete.svg`)"
                      class="is-cursor-pointer"
                      @customClickEvent="deleteAlertCard(patient)"
                    />
                  </div>
                </div>
              </div>                                
            </div>
          </article>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@components/shared/images/SvgIcon.vue";

export default {
  components: {
    "lila-svg-icon": SvgIcon
  },
  props: {
    dataList: {
      required: true,
      type: Array,
      default: () => {
        return [{
          id: "",
          alertId: "",
          name: "",
          profilePicture: "",
          cid: "",
          diagnostic: "",
          date: ""
        }];
      }
    },
    routerPath: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    clickEvent(patient){
      this.$emit("customClickEvent", patient);
    },
    deleteAlertCard(alert){
      this.$emit("customDeleteAlertCard", alert);
    },
    diagnosticType(diagnostic) {
      return diagnostic.isEva ? 
        `Dor ${diagnostic.intensity}` : 
        `${diagnostic.name} ${this.intensityPainName(diagnostic.intensity)}`;
    },
    intensityPainName(intensity){
      let intensityList = ["", "Leve", "Moderado", "Grave", "Muito Grave"];

      return intensityList[intensity] != undefined ? intensityList[intensity] : "";
    },
    getProfilePicture(profilePicture) {
      return profilePicture != "" ? profilePicture : require(`@components/shared/lila/profilePicturePlaceholder/${(Math.floor(Math.random() * 5) + 1)}.png`);
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/diagnosticCard.css';
</style>